import borders from 'assets/theme/base/borders';
import colors from 'assets/theme/base/colors';
import pxToRem from 'assets/theme/functions/pxToRem';

const { borderWidth } = borders;
const { light, white, text } = colors;

export default {
  styleOverrides: {
    root: {
      padding: `${pxToRem(12)} ${pxToRem(16)}`,
      borderBottom: `${borderWidth[1]} solid ${light.main}`,
    },
    head: {
      backgroundColor: text.main,
      color: white.main,
      fontSize: '1.2rem',
    },
  },
};
