import { useState } from 'react';
import {
  Container,
  Box,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  styled,
  useTheme,
  Menu,
  Button,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import CustomButton from 'Components/ui/CustomButton';
import logoImg from 'assets/Logo.svg';
import { getStorage, removeStorage } from 'utils/storage';

export const Navbar = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();
  const [anchorAdmin, setAnchorAdmin] = useState(null);
  const [mobileMenu, setMobileMenu] = useState({
    left: false,
  });
  const user = getStorage('user');

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.type === 'Tab' || event.type === 'Shift')) {
      return;
    }

    setMobileMenu({ ...mobileMenu, [anchor]: open });
  };

  const handleOpenAdminMenu = (event) => {
    setAnchorAdmin(event.currentTarget);
  };

  const handleCloseAdminMenu = () => {
    setAnchorAdmin(null);
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {['accueil', 'entreprises', 'jobs', 'média'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemText
                primary={text.toUpperCase()}
                onClick={() => {
                  switch (index) {
                    case 0:
                      navigate('/');
                      break;
                    case 1:
                      navigate('/company');
                      break;
                    case 2:
                      navigate('/jobs');
                      break;
                    case 3:
                      navigate('/media');
                      break;
                    default:
                      navigate('/');
                  }
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <NavbarContainer maxWidth="xl">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '2.5rem',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <CustomMenuIcon onClick={toggleDrawer('left', true)} />
          <Drawer anchor="left" open={mobileMenu['left']} onClose={toggleDrawer('left', false)}>
            {list('left')}
          </Drawer>
          <NavbarLogo src={logoImg} alt="logo" onClick={() => navigate('/')} />
        </Box>

        <NavbarLinksBox>
          <NavLink
            to="/company"
            className={({ isActive, isPending }) =>
              isPending ? 'pending' : isActive ? 'active' : ''
            }
          >
            {({ isActive, isPending }) => (
              <Button className={isPending ? 'pending' : isActive ? 'active' : ''}>
                <Label variant="subtitle2">{'entreprises'.toUpperCase()}</Label> {/*TK: companies*/}
              </Button>
            )}
          </NavLink>

          <NavLink
            to="/jobs"
            className={({ isActive, isPending }) =>
              isPending ? 'pending' : isActive ? 'active' : ''
            }
          >
            {({ isActive, isPending }) => (
              <Button className={isPending ? 'pending' : isActive ? 'active' : ''}>
                <Label variant="subtitle2">{'jobs'.toUpperCase()}</Label> {/*TK: jobs*/}
              </Button>
            )}
          </NavLink>

          <NavLink
            to="/media"
            className={({ isActive, isPending }) =>
              isPending ? 'pending' : isActive ? 'active' : ''
            }
          >
            {({ isActive, isPending }) => (
              <Button className={isPending ? 'pending' : isActive ? 'active' : ''}>
                <Label variant="subtitle2">{'média'.toUpperCase()}</Label> {/*TK: media*/}
              </Button>
            )}
          </NavLink>

          {user && (
            <>
              <Button
                id="admin-button"
                onClick={handleOpenAdminMenu}
                sx={{ px: 0 }}
                aria-controls={anchorAdmin ? 'admin-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={anchorAdmin ? 'true' : undefined}
                className={location.pathname.match('^/admin') ? 'active' : undefined}
              >
                <Label variant="subtitle2">ADMIN</Label>
              </Button>

              <Menu
                id="admin-menu"
                anchorEl={anchorAdmin}
                open={Boolean(anchorAdmin)}
                onClose={handleCloseAdminMenu}
                aria-labelledby="admin-button"
              >
                <NavLink
                  style={{ textDecoration: 'none' }}
                  to="/admin/companies"
                  onClick={handleCloseAdminMenu}
                >
                  <Label variant="subtitle2">Gérer les entreprises</Label>
                </NavLink>

                <NavLink
                  style={{ textDecoration: 'none' }}
                  to="/admin/jobs"
                  onClick={handleCloseAdminMenu}
                >
                  <Label variant="subtitle2">Gérer les jobs</Label>
                </NavLink>

                {user?.roles?.some((uRole) => uRole === 'ROLE_ADMIN') && (
                  <NavLink
                    style={{ textDecoration: 'none' }}
                    to="/admin/trends"
                    onClick={handleCloseAdminMenu}
                  >
                    <Label variant="subtitle2">Gérer les trends</Label>
                  </NavLink>
                )}
              </Menu>
            </>
          )}
        </NavbarLinksBox>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '1rem',
        }}
      >
        {user ? (
          <>
            <Typography variant="body2">Bienvenue {user.firstName}</Typography>
            <Button
              variant="outlined"
              onClick={() => {
                removeStorage('user');
                removeStorage('refreshToken');
                removeStorage('token');
                navigate('/login');
              }}
            >
              Déconnexion
            </Button>
          </>
        ) : (
          <>
            <CustomButton
              component={NavLink}
              to="https://solutions.alleyesonme.jobs/"
              borderColor={theme.palette.dark.main}
              backgroundColor={theme.palette.white.main}
              color="#0F1B4C"
              buttonText="Employeurs"
              fontFamily="Arvo Regular"
              border={'2px solid ' + theme.palette.grey[300]}
              borderHover={'2px solid ' + theme.palette.dark.main}
            />
            {/*TK: employers*/}
            <CustomButton
              component={NavLink}
              to="/login"
              borderColor={theme.palette.dark.main}
              backgroundColor={theme.palette.grey[300]}
              color={theme.palette.dark.main}
              buttonText="Connexion"
              fontFamily="Arvo Regular"
              border={'2px solid ' + theme.palette.grey[300]}
              borderHover={'2px solid ' + theme.palette.dark.main}
            />
            {/*TK: login*/}
          </>
        )}
      </Box>
    </NavbarContainer>
  );
};

const Label = styled(Typography)(() => ({
  fontSize: '12px',
  color: '#03003D',
  fontWeight: 'bold',
  cursor: 'pointer',
  '&:hover': {
    color: '#FF4F00',
  },
}));

const NavbarLinksBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
  '& a': {
    paddingLeft: 0,
    paddingRight: 0,
  },
  '& a.active *, & button.active *': {
    color: '#FF4F00',
  },
}));

const CustomMenuIcon = styled(MenuIcon)(({ theme }) => ({
  cursor: 'pointer',
  display: 'none',
  marginRight: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    display: 'block',
  },
}));

const NavbarContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minHeight: '5vh',
  padding: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
  },
}));

const NavbarLogo = styled('img')(({ theme }) => ({
  cursor: 'pointer',
  maxWidth: 200,
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export default Navbar;
