import axios from 'axios';
import { parse, stringify } from 'qs';

export const setupApi = () => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.defaults.paramsSerializer = {
    encode: parse,
    serialize: (params) => stringify(params, { indices: false }),
  };
};

export const getCleanUrlFromService = (value) => {
  if (value.includes('http://') || value.includes('https://')) return value;
  return `//${value}`;
};
