import React, { useEffect, useState } from 'react';
import { Alert, Avatar, TextField, Box, Typography, Container, Button } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { useMutation } from 'react-query';
import { useForm } from 'react-hook-form';

import AEOMButton from 'Components/ui/AEOMButton';
import { authSuccess, authUser, getUser } from 'utils/user';
import { setStorage } from '../utils/storage';

function Login() {
  const [alert, setAlert] = useState();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { handleSubmit, register } = useForm({
    mode: 'all',
  });

  const { mutateAsync: getUserInfo } = useMutation({
    mutationFn: (id) => getUser(id),
    onSuccess: async (result) => {
      setStorage('user', result.data);
      navigate('/');
    },
  });

  const { mutateAsync: loginUser } = useMutation({
    mutationFn: (data) => authUser(data),
    onSuccess: async (result) => {
      // TODO: onSuccess is triggered even when an error is happening

      const { data, response } = result;
      // console.log('response', response);
      if (response?.data?.message === 'Identifiants invalides.') {
        return setAlert({
          severity: 'error',
          message: 'Une erreur est survenue, veuillez réessayer',
        });
      }
      if (data) {
        authSuccess(data);
        const { token } = data;
        const userId = jwtDecode(token).id;
        await getUserInfo(userId);
      }
    },
    onError: async () => {
      // console.log('onError', JSON.stringify(result));
    },
  });

  const onSubmit = async (data) => {
    await loginUser(data);
  };

  useEffect(() => {
    if (searchParams.get('signature')) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/login`, {
          params: {
            expires: searchParams.get('expires'),
            id: searchParams.get('id'),
            signature: searchParams.get('signature'),
            token: searchParams.get('token'),
          },
        })
        .then(() => {
          setAlert({
            severity: 'success',
            message:
              "Félicitations ! Votre compte est actif, vous pouvez désormais vous connecter pour accéder à l'édition du profil de votre entreprise.",
          });
        })
        .catch(() => {
          setAlert({
            severity: 'error',
            message: 'Une erreur est survenue, veuillez réessayer',
          });
        });
    }
  }, []);

  return (
    <>
      <Container
        maxWidth="md"
        sx={{ py: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Connexion
        </Typography>

        {alert && (
          <Alert
            variant="filled"
            severity={alert.severity}
            sx={{ mt: 3, width: '100%' }}
            onClose={() => setAlert(null)}
          >
            {alert.message}
          </Alert>
        )}

        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3, width: '100%' }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            {...register('email')}
            autoComplete="email"
            autoFocus
          />

          <TextField
            margin="normal"
            required
            fullWidth
            {...register('password')}
            label="Mot de passe"
            type="password"
            id="password"
            autoComplete="current-password"
          />

          <Button component={Link} to="/resetPassword" sx={{ p: 0, minWidth: 'inherit', mb: 1 }}>
            Mot de passe oublié ?
          </Button>

          <AEOMButton color="primary" type="submit" sx={{ width: '100%', mt: 3, mb: 2 }}>
            Connexion
          </AEOMButton>
        </Box>
      </Container>
    </>
  );
}

export default Login;
