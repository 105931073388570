import React from 'react';
import { Container, Typography } from '@mui/material';

function privacyPolicy() {
  return (
    <>
      <Container disableGutters maxWidth="md" component="main" sx={{ pt: 8, pb: 6 }}>
        <Typography
          fontStyle="italic"
          fontWeight="light"
          color="dark"
          fontSize="16px"
          component="p"
          mt={2}
          mb={2}
        >
          All Eyes On Me rend les entreprises plus attractives en faisant rayonner leur marque
          employeur et en permettant d’offrir une expérience de recrutement fluide aux candidats et
          aux recruteurs. All Eyes On Me met également à disposition de ses utilisateurs un média
          sur le travail reprenant des articles, interviews et des ebooks.
        </Typography>
        <Typography fontWeight="light" color="dark" fontSize="16px" component="p" mt={2} mb={4}>
          Nous vous invitons à lire avec attention le présent document (ci-après, la « Politique de
          Confidentialité »). Pour toute question concernant ce document et, d’une manière générale,
          sur la collecte et le traitement de vos informations personnelles par la société et toute
          entitée affiliée à JBBD Srl-s (ci-après, « Nous », « All Eyes On Me » ou « AEOM »),
          n’hésitez pas à nous contacter via l’adresse e-mail suivante : privacy@alleyesonme.jobs
        </Typography>
        <hr></hr>
        <Typography component="h2" color="dark" gutterBottom mt={2} mb={2}>
          Sommaire
        </Typography>
        <Typography
          sx={{ ul: { ml: { xs: 4, md: 4 } }, mt: 2 }}
          fontWeight="light"
          fontSize="16px"
          color="dark"
          component="p"
          mt={2}
          mb={2}
        >
          <ul>
            <li>
              Définitions
              <ul>
                <li>Définitions relatives à la protection des données personnelles</li>
                <li>Définitions relatives aux Services</li>
                <li>Définitions relatives aux personnes concernées</li>
              </ul>
            </li>
            <li>Préambule et champ d’application</li>
            <li>
              Acceptation et mises à jour des CGU
              <ul>
                <li>Acceptation de nos termes</li>
                <li>Mise à jour</li>
              </ul>
            </li>
            <li>
              Responsable de traitement et délégué à la protection des données
              <ul>
                <li>Concernant l’utilisation du Site AEOM</li>
                <li>Concernant l’utilisation du Produit</li>
              </ul>
            </li>
            <li>Catégories de Données Personnelles collectées et traitée</li>
            <li>Caractère obligatoire ou facultatif de fournir vos Données</li>
            <li>
              Finalités de la collecte et du traitement de vos Données Personnelles
              <ul>
                <li>Dans le cadre de l’utilisation de notre Site AEOM</li>
                <li>Dans le cadre de l’utilisation de notre Produit</li>
              </ul>
            </li>
            <li>
              Durées de conservation de vos Données Personnelles
              <ul>
                <li>Dans le cadre de l’utilisation de notre Site AEOM</li>
                <li>Dans le cadre de l’utilisation de notre Produit</li>
              </ul>
            </li>
            <li>
              Destinataires de vos Données Personnelles
              <ul>
                <li>Partage vers des tiers</li>
                <li>Transferts hors UE</li>
              </ul>
            </li>
            <li>Sécurité de vos Données Personnelles</li>
            <li>Respect de vos droits</li>
          </ul>
        </Typography>
        <Typography color="dark" component="h3" mt={3} mb={2}>
          DÉFINITIONS
        </Typography>
        <Typography color="dark" component="h4" mt={0} mb={0}>
          1.1 Définitions relatives à la protection des données personnelles
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
          Données Personnelles : désigne toute information se rapportant à une personne physique
          identifiée ou identifiable.
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
          Finalité : désigne l’objectif principal de l’utilisation de données personnelles.
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
          Réglementation Données Personnelles : désigne la réglementation en vigueur applicable au
          traitement des données personnelles et, notamment, le Règlement (UE) 2016/679 du Parlement
          européen et du Conseil du 27 avril 2016 applicable à compter du 25 mai 2018 (ci-après le “
          Règlement général sur la protection des données “ ou “ RGPD “) et la loi n° 78-17 du 6
          janvier 1978 relative à l’informatique, aux fichiers et aux libertés telle que modifiée.
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
          Traitement : désigne une opération, ou ensemble d’opérations, portant sur des données
          personnelles, quel que soit le procédé utilisé.
        </Typography>
        <Typography color="dark" component="h4" mt={0} mb={0}>
          1.2. Définitions relatives aux Services
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
          Contrat : désigne le Contrat d’Abonnement et ses Annexes, ainsi que tout Devis applicable
          signé entre le Client et AEOM.
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
          Services : désigne l’ensemble des services fournis par AEOM via l’utilisation de son Site
          ou de son Produit.
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
          Site AEOM : désigne le site Internet de AEOM ainsi que ses sous-domaines.
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
          Produit : désigne les applications logicielles mises à disposition du Client par AEOM dans
          le cadre de la fourniture des Services.
        </Typography>
        <Typography color="dark" component="h4" mt={0} mb={0}>
          1.3 Définitions relatives aux personnes concernées
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
          Candidat : désigne l’utilisateur membre du Site AEOM qui postule activement à des offres
          d’emploi disponibles sur le Site AEOM.
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          Client : désigne l’entité qui a passé une commande pour l’utilisation du Produit par le
          biais de la signature d’un devis.
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          Lecteur : désigne l’utilisateur qui navigue sur le Site AEOM sans avoir créé un compte
          membre.
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          Membre : désigne l’utilisateur du Site AEOM qui a créé un compte membre et y a renseigné
          ses informations personnelles.
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          Personne concernée : désigne la personne physique qui peut être identifiée, directement ou
          indirectement concernée par le traitement de données personnelles.
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          Utilisateurs Autorisés : désigne les employés, sous-traitants, consultants représentants
          du Client qui sont autorisés par ce dernier à utiliser le Produit.
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          Utilisateurs du Site : désigne l’ensemble des utilisateurs du Site AEOM qu’ils soient
          indistinctement lecteur, membre ou candidat.
        </Typography>
        <Typography color="dark" component="h3" mt={3} mb={2}>
          2. PRÉAMBULE ET CHAMP D’APPLICATION
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          AEOM est soucieux de préserver la confidentialité de vos informations personnelles et nous
          attachons une grande importance à la protection de la vie privée des Utilisateurs de nos
          Services.
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          La Politique de Confidentialité a pour objectif de définir les règles applicables à la
          collecte et au traitement de vos informations personnelles (ci-après une ou les «
          Donnée(s) Personnelle(s) ») via notre Site AEOM ainsi que via son Produit proposé à ses
          Clients (ci-après conjointement nos « Sites »).
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          Notre Politique de Confidentialité détaille les Données Personnelles que nous collectons
          et traitons via nos Sites, les raisons du traitement de vos Données Personnelles, la
          manière dont nous les utilisons et les fonctionnalités proposées pour vous permettre
          notamment d’accéder et mettre à jour vos Données Personnelles.
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          Elle s’applique aux Utilisateurs du Site ainsi qu’aux Utilisateurs Autorisés sur notre
          Produit tels que définis au sein de la section <i>“1. Définitions”.</i>
        </Typography>

        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          Notre Politique de Confidentialité ne s’applique pas aux services proposés par des
          sociétés tierces, exploitant des sites web et outils de nos partenaires ou d’autres sites
          web ou outils accessibles à partir de nos services. Nous ne sommes pas responsables de la
          façon dont ces sites utilisent vos Données Personnelles.
        </Typography>
        <Typography color="dark" component="h3" mt={3} mb={2}>
          3. ACCEPTATION ET MISE À JOUR DE NOTRE POLITIQUE DE CONFIDENTIALITÉ
        </Typography>
        <Typography color="dark" component="h4" mt={0} mb={0}>
          3.1 Acceptation de nos termes :
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          Lors de votre première utilisation de nos Services sur le Site AEOM et le Produit, vous
          êtes amenés à accepter la présente Politique de Confidentialité. Vous confirmez ainsi en
          avoir pris connaissance et l’accepter sans restrictions ni réserve. Si vous êtes en
          désaccord avec l’un de ses termes, vous êtes libre de ne pas utiliser nos Services.
        </Typography>
        <Typography color="dark" component="h4" mt={0} mb={0}>
          3.2 Mise à jour
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          Nous pouvons être amenés à modifier les termes de la présente Politique de
          Confidentialité, notamment en cas de mise en œuvre d’un nouveau Traitement de Données
          Personnelles. Vous êtes invité à consulter cette page régulièrement afin de vous assurer
          que vous êtes en accord avec toute modification. Vous serez informé de ces modifications
          soit par une mention spéciale sur nos Sites, soit par une notification par email.
        </Typography>
        <Typography color="dark" component="h3" mt={3} mb={2}>
          4. RESPONSABLE DE TRAITEMENT ET DÉLÉGUÉ À LA PROTECTION DES DONNÉES
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          Un responsable du traitement est une entité qui détermine les Finalités et les moyens du
          Traitement de vos Données Personnelles et qui est responsable envers vous du respect de la
          Réglementation Données Personnelles.
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          Nous avons désigné un Délégué à la Protection des Données Personnelles (DPO) qui sera
          votre contact pour toute question relative au traitement de vos Données Personnelles. Il
          sera joignable par courrier électronique à l’adresse privacy@alleyesonme.jobs.
        </Typography>
        <Typography color="dark" component="h4" mt={0} mb={0}>
          4.1 Concernant l’utilisation du Site AEOM :
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          Nous sommes responsable de traitement pour tous les Traitements effectués sur notre Site
          AEOM excepté lorsque vous postulez à des offres d’emploi de nos Clients. Dans ce cas, AEOM
          agit en tant que sous-traitant de l’entreprise à laquelle vous postulez. Pour accéder au
          détail des Finalités et Traitement de Données Personnelles effectués sur notre Site AEOM,
          veuillez vous référer à la section 7 de la présente Politique de Confidentialité.
        </Typography>
        <Typography color="dark" component="h4" mt={0} mb={0}>
          4.2 Concernant l’utilisation de notre Produit
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          Nous sommes responsable de traitement lors de la création des accès sur notre Produit des
          Utilisateurs Autorisés de nos Clients.
        </Typography>
        <Typography color="dark" component="h3" mt={3} mb={2}>
          5. CATÉGORIES DE DONNÉES PERSONNELLES COLLECTÉES ET TRAITÉES
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          <table border="1px solid" style={{ borderCollapse: 'collapse' }}>
            <tr>
              <th>Site concerné</th>
              <th>Catégories de Données collectées</th>
              <th>Détails des Données collectées</th>
              <th>Personnes concernées</th>
            </tr>
            <tr>
              <td>Site AEOM</td>
              <td>Données techniques relatives à l’usage du Site AEOM</td>
              <td>
                <dl>
                  <dd>- Adresse IP</dd>
                  <dd>- Cookies</dd>
                  <dd>- Logs</dd>
                  <dd>- Données de connexion et de navigation</dd>
                </dl>
                Pour plus d’informations, veuillez consulter notre Politique de Gestion des Cookies
              </td>
              <td>
                <dl>
                  <dd>- Lecteurs</dd>
                  <dd>- Membres</dd>
                  <dd>- Candidats</dd>
                </dl>
              </td>
            </tr>
            <tr>
              <td>Site AEOM</td>
              <td>Données d’identification des Utilisateurs du Site AEOM</td>
              <td>
                <dl>
                  <dd>- Civilité</dd>
                  <dd>- Prénom</dd>
                  <dd>- Nom</dd>
                  <dd>- Adresse email</dd>
                  <dd>- Numéro de téléphone</dd>
                  <dd>- Date de naissance</dd>
                  <dd>- Ville</dd>
                  <dd>- Photo de profil</dd>
                  <dd>- Profil réseaux sociaux</dd>
                </dl>
              </td>
              <td>
                <dl>
                  <dd>- Membres</dd>
                  <dd>- Candidats</dd>
                </dl>
              </td>
            </tr>
            <tr>
              <td>Site AEOM</td>
              <td>Données relatives à la vie professionnelle des Utilisateurs du Site AEOM</td>
              <td>
                <dl>
                  <dd>- Secteur d’activité</dd>
                  <dd>- Années d’expérience</dd>
                  <dd>- CV</dd>
                  <dd>- Lettre de motivation</dd>
                </dl>
              </td>
              <td>
                <dl>
                  <dd>- Membres</dd>
                  <dd>- Candidats</dd>
                </dl>
              </td>
            </tr>
            <tr>
              <td>Produit</td>
              <td>Données d’identification des Utilisateurs Autorisés du Produit</td>
              <td>
                <dl>
                  <dd>- Prénom</dd>
                  <dd>- Nom</dd>
                </dl>
              </td>
              <td>
                <dl>
                  <dd>- Utilisateurs Autorisés</dd>
                </dl>
              </td>
            </tr>
            <tr>
              <td>Produit</td>
              <td>
                Données relatives à la vie professionnelle des Utilisateurs Autorisés du Produit
              </td>
              <td>
                <dl>
                  <dd>- Adresse email professionnelle</dd>
                  <dd>- Poste</dd>
                  <dd>- Numéro de téléphone</dd>
                  <dd>- Langue</dd>
                  <dd>- Photo de profil</dd>
                  <dd>- Données de connexion (adresse IP)</dd>
                </dl>
              </td>
              <td>
                <dl>
                  <dd>- Utilisateurs Autorisés</dd>
                </dl>
              </td>
            </tr>
          </table>
        </Typography>
        <Typography color="dark" component="h3" mt={3} mb={2}>
          6. Caractère obligatoire ou facultatif de fournir vos Données
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          Les Données Personnelles indispensables à AEOM pour atteindre les finalités des
          Traitements sont signalées par un astérisque au sein des formulaires de collecte.
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          Si vous ne renseignez pas ces champs obligatoires, nous ne serons pas en mesure de vous
          fournir nos Services.
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          Les Données Personnelles non signalées par un astérisque sont facultatives ; elles nous
          permettent de mieux vous connaître et d’améliorer nos Services.
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          Concernant les Données Personnelles collectées lorsque vous naviguez sur le Site AEOM
          merci de vous référer directement à notre Politique de Gestion des Cookies.
        </Typography>
        <Typography color="dark" component="h3" mt={3} mb={2}>
          7. FINALITÉS DE LA COLLECTE ET DU TRAITEMENT DE VOS DONNÉES PERSONNELLES
        </Typography>
        <Typography color="dark" component="h4" mt={0} mb={0}>
          7.1 Dans le cadre de l’utilisation de notre Site AEOM :
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          <table border="1px solid" style={{ borderCollapse: 'collapse' }}>
            <tr>
              <th>Finalités de traitement</th>
              <th>Base juridique</th>
              <th>Personne concernée</th>
            </tr>
            <tr>
              <td>
                Gestion et utilisation du Site AEOM :
                <dl>
                  <dd>
                    -Administration générale du Site afin d’assurer son bon fonctionnement et sa
                    sécurité ;
                  </dd>
                  <dd>
                    -Traitement des demandes de renseignement via les différents formulaires
                    disponibles sur notre Site ;
                  </dd>
                  <dd>
                    -Réalisation de statistiques, suivi de l’activité, analyse de la fréquentation
                    des pages consultées ;
                  </dd>
                  <dd>
                    - Amélioration du Site et proposition d’un contenu performant pour vous et votre
                    ordinateur ;
                  </dd>
                  <dd>
                    - Dépôt des cookies conformément aux choix effectués via notre bandeau cookies ;
                  </dd>
                  <dd>
                    - Résolution de tout litige ou anomalie dans le cadre de l’utilisation de nos
                    Services et notamment lutter contre la fraude en ligne.
                  </dd>
                </dl>
              </td>
              <td>
                <dl>
                  <dd>- Intérêt légitime</dd>
                  <dd>-Consentement</dd>
                </dl>
              </td>
              <td>
                <dl>
                  <dd>- Lecteurs</dd>
                  <dd>- Membres</dd>
                  <dd>- Candidats</dd>
                </dl>
              </td>
            </tr>
            <tr>
              <td>
                Création et gestion de votre espace membre :
                <dl>
                  <dd>- Création d’un profil membre sur notre Site ;</dd>
                  <dd>
                    - Amélioration de l’expérience candidat en proposant des offres d’emploi
                    personnalisées et adaptées à l’historique de recherche sur notre Site ;
                  </dd>
                  <dd>
                    - Traitement des demandes de renseignement et assistance dans le cadre de votre
                    utilisation de nos Services.
                  </dd>
                </dl>
              </td>
              <td>
                <dl>
                  <dd>- Exécution contractuelle</dd>
                  <dd>- Intérêt légitime</dd>
                </dl>
              </td>
              <td>
                <dl>
                  <dd>- Membres</dd>
                  <dd>- Candidats</dd>
                </dl>
              </td>
            </tr>
            <tr>
              <td>
                Gestion de vos candidatures aux offres d’emploi :
                <dl>
                  <dd>- Gérer le traitement de vos candidatures à nos propres offres d’emploi ;</dd>
                  <dd>
                    - Fourniture de nos Services dans le cadre de l’utilisation d’un espace membre
                    (postuler aux offres d’emploi de nos Clients, gestion et suivi de vos
                    candidatures au sein de votre espace).
                  </dd>
                </dl>
              </td>
              <td>
                <dl>
                  <dd>- Exécution des mesures précontractuelles</dd>
                  <dd>- Exécution contractuelle</dd>
                  <dd>- Intérêt légitime</dd>
                </dl>
              </td>
              <td>
                <dl>
                  <dd>- Candidats</dd>
                </dl>
              </td>
            </tr>
            <tr>
              <td>
                Gestion et traitement de vos commandes de produits sur notre Site :
                <dl>
                  <dd>
                    - Commande de nos Ebook ou tout autre contenu via notre site
                    solutions.alleyesonme.jobs
                  </dd>
                  <dd>
                    - Envoi de notre newsletter, nos contenus et partage de nos nouveautés par
                    courrier électronique
                  </dd>
                </dl>
              </td>
              <td>
                <dl>
                  <dd>- Exécution contractuelle</dd>
                  <dd>-Consentement</dd>
                </dl>
              </td>
              <td>
                <dl>
                  <dd>
                    - Tout Utilisateur qui s’abonne à notre newsletter ou qui commande un produit
                    sur notre Site
                  </dd>
                </dl>
              </td>
            </tr>
            <tr>
              <td>
                Opérations marketing :
                <dl>
                  <dd>- Réalisation de campagnes de prospection BtoB téléphone) ;</dd>
                  <dd>- Envoi d’emails promotionnels ;</dd>
                  <dd>- Elaboration de statistiques ;</dd>
                  <dd>- Réalisation d’enquêtes de satisfaction.</dd>
                </dl>
              </td>
              <td>
                <dl>
                  <dd>- Intérêt légitime</dd>
                  <dd>-Consentement</dd>
                </dl>
              </td>
              <td>
                <dl>
                  <dd>- Prospects</dd>
                  <dd>- Clients</dd>
                </dl>
              </td>
            </tr>
          </table>
        </Typography>
        <Typography color="dark" component="h4" mt={0} mb={0}>
          7.2 Dans le cadre de l’utilisation de notre Produit :
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          <table border="1px solid" style={{ borderCollapse: 'collapse' }}>
            <tr>
              <th>Finalités de traitement</th>
              <th>Base juridique</th>
              <th>Personne concernée</th>
            </tr>
            <tr>
              <td>
                Gestion technique de notre Produit :
                <dl>
                  <dd>
                    - Administration générale du Produit afin d’assurer son bon fonctionnement sa
                    sécurité ;
                  </dd>
                  <dd>
                    - Réalisation de statistiques, suivi de l’activité, analyse de la fréquentation
                    et ses pages consultées ;
                  </dd>
                  <dd>
                    - Amélioration du Produit et proposition d’un contenu performant pour les
                    Utilisateurs Autorisés ;
                  </dd>
                  <dd>
                    - Résolution de tout litige ou anomalie dans le cadre de l’utilisation de nos
                    Services et notamment lutter contre la fraude en ligne
                  </dd>
                </dl>
              </td>
              <td>
                <dl>
                  <dd>- Intérêt légitime</dd>
                </dl>
              </td>
              <td>
                <dl>
                  <dd>- Utilisateurs Autorisés</dd>
                </dl>
              </td>
            </tr>
            <tr>
              <td>
                Fourniture de nos Services :
                <dl>
                  <dd>- Création et gestion des comptes des Utilisateurs Autorisés du Client </dd>
                  <dd>
                    - Communications et partage de toute évolutions et nouveautés sur le Produit ;
                  </dd>
                  <dd>
                    - Traitement des demandes de renseignement via les différents formulaires
                    disponibles sur notre Produit ;
                  </dd>
                  <dd>- Réalisation d’enquêtes de satisfaction.</dd>
                </dl>
              </td>
              <td>
                <dl>
                  <dd>- Exécution contractuelle</dd>
                </dl>
              </td>
              <td>
                <dl>
                  <dd>- Utilisateurs Autorisés</dd>
                </dl>
              </td>
            </tr>
            <tr>
              <td>
                Gestion comptable et commerciale avec les Clients :
                <dl>
                  <dd>- Paiement des Services et suivi de la facturation</dd>
                  <dd>- Gestion des impayés et du contentieux ;</dd>
                  <dd>- Tenue des registres comptables et justificatifs légaux ;</dd>
                  <dd>- Gestion du suivi commercial.</dd>
                </dl>
              </td>
              <td>
                <dl>
                  <dd>- Exécution contractuelle</dd>
                </dl>
              </td>
              <td>
                <dl>
                  <dd>- Clients</dd>
                </dl>
              </td>
            </tr>
          </table>
        </Typography>
        <Typography color="dark" component="h3" mt={3} mb={2}>
          8. DURÉE DE CONSERVATION DE VOS DONNÉES PERSONNELLES
        </Typography>
        <Typography color="dark" component="h4" mt={0} mb={0}>
          8.1 Dans le cadre de l’utilisation de notre Site AEOM :
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          <table border="1px solid" style={{ borderCollapse: 'collapse' }}>
            <tr>
              <th>Personne concernée</th>
              <th>Durée de conservation</th>
            </tr>
            <tr>
              <td>Lecteurs</td>
              <td>
                Les délais de conservation des cookies ou autres traceurs sont détaillés au sein de
                la Politique de Gestion des Cookies. Vos données de contact collectées dans le cadre
                de votre inscription à notre newsletter sont conservées jusqu’à ce que vous en
                demandiez la suppression via le lien de désinscription disponible au sein de nos
                communications.
              </td>
            </tr>
            <tr>
              <td>Prospects (BtoB)</td>
              <td>
                Vos données sont supprimées au bout de 3 ans à compter du dernier contact que vous
                avez avec nous.
              </td>
            </tr>
            <tr>
              <td>Membres</td>
              <td>
                Vos Données Personnelles seront donc conservées durant toute l’utilisation des
                Services et jusqu’à la fermeture de votre compte.
              </td>
            </tr>
            <tr>
              <td>Candidats</td>
              <td>
                Vos données de candidature aux offres de All Eyes On Me seront anonymisées dans un
                délai maximal de 2 ans à compter de leur collecte.
              </td>
            </tr>
          </table>
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          Avant l’expiration de ces délais, nous pourrons reprendre contact avec vous pour savoir si
          vous souhaitez mettre en place une durée de conservation supplémentaire, auquel cas les
          délais indiqués ci-dessus recommenceront à courir.
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          A l’issue de ces délais et de la clôture effective de votre compte, vos Données
          Personnelles seront anonymisées. Elles serviront alors exclusivement à des fins
          statistiques.
        </Typography>
        <Typography color="dark" component="h4" mt={0} mb={0}>
          8.2 Dans le cadre de l’utilisation de notre Produit :
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          <table border="1px solid" style={{ borderCollapse: 'collapse' }}>
            <tr>
              <th>Personne concernée</th>
              <th>Durée de conservation</th>
            </tr>
            <tr>
              <td>Utilisateurs Autorisés</td>
              <td>
                Vos Données Personnelles seront conservées durant la durée du Contrat entre AEOM et
                le Client dans les conditions décrites au sein de ce dernier.
              </td>
            </tr>
            <tr>
              <td>Clients</td>
              <td>
                Vos données sont conservées durant toute la durée de notre Contrat. Elles sont
                supprimées au bout de 5 ans à compter de la fin de notre relation contractuelle afin
                de satisfaire aux obligations en matière de prescription légale.
              </td>
            </tr>
          </table>
        </Typography>
        <Typography color="dark" component="h3" mt={3} mb={2}>
          9. DESTINATAIRES DE VOS DONNÉES PERSONNELLES
        </Typography>
        <Typography color="dark" component="h4" mt={0} mb={0}>
          9.1 Partage vers des tiers
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          Les Données Personnelles que vous nous transmettez dans le cadre de l’utilisation de nos
          Sites sont susceptibles d’être communiquées à :
        </Typography>
        <Typography
          sx={{ ml: { xs: 4, md: 4 }, mt: 2 }}
          fontWeight="light"
          fontSize="16px"
          color="dark"
          component="p"
          mt={2}
          mb={2}
        >
          <li>
            Nos sous-traitants et notamment nos prestataires techniques, hébergeurs, suivi client,
            enquêtes de satisfaction, gestion d’incident de sécurité ou activité frauduleuse, etc.
          </li>
          <li>
            Les réseaux sociaux dans le cadre du partage de contenu (à savoir Facebook, Twitter,
            LinkedIn et TikTok) ;
          </li>
          <li>
            Pour des raisons d’ordre légale vos informations et Données Personnelles pourront être
            divulguées à un tiers si AEOM y est contraint par la loi, une disposition réglementaire,
            ou une ordonnance judiciaire, ou encore si cette divulgation est rendue nécessaire pour
            les besoins d’une enquête, injonction ou d’une procédure judiciaire, sur le territoire
            national ou à l’étranger.
          </li>
        </Typography>
        <Typography color="dark" component="h4" mt={0} mb={0}>
          9.2 Transfert hors UE
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          Les Données Personnelles traitées dans le cadre de l’utilisation de nos Sites sont
          stockées sur des serveurs situés au sein de l’Union Européenne.
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          Lorsque AEOM fait appel à des prestataires situés hors de l’UE des garanties de protection
          et de sécurisation des transferts sont prises. Ainsi, soit ces destinataires se trouvent
          dans un pays bénéficiant d’une décision d’adéquation, soit des clauses contractuelles
          types approuvées par la Commission Européenne ont été signées.
        </Typography>
        <Typography color="dark" component="h3" mt={3} mb={2}>
          10. SÉCURITÉ DE VOS DONNÉES PERSONNELLES
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          Nous mettons en œuvre des mesures organisationnelles et techniques pour protéger la
          sécurité de vos Données Personnelles contre tout accès et toute divulgation, modification,
          endommagement ou destruction non autorisés.
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          La société AEOM et ses prestataires techniques et d’hébergement ont notamment déployé des
          mesures appropriées pour assurer l’intégrité, la confidentialité et la sécurité de vos
          Données Personnelles.
        </Typography>
        <Typography color="dark" component="h3" mt={3} mb={2}>
          11. RESPECT DE VOS DROITS
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          Vous disposez d’un droit d’accès aux Données vous concernant, du droit d’en demander la
          rectification, l’effacement ou la portabilité, ainsi que du droit de demander la
          limitation du traitement de vos Données, de retirer votre consentement et de définir des
          directives sur le sort de vos Données après votre mort.
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          Vous avez également la possibilité de vous opposer, à tout moment :
        </Typography>
        <Typography
          sx={{ ml: { xs: 4, md: 4 }, mt: 2 }}
          fontWeight="light"
          fontSize="16px"
          color="dark"
          component="p"
          mt={2}
          mb={2}
        >
          <li>Aux traitements à des fins de prospection commerciale, mis en œuvre par AEOM ;</li>
          <li>
            Pour des raisons tenant à votre situation particulière, aux traitements fondés sur
            l’intérêt légitime de AEOM.
          </li>
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          Ces droits peuvent être exercés à tout moment en contactant le Délégué à la protection des
          données (DPO) à l’adresse privacy@alleyesonme.jobs. Vous avez également la possibilité de
          supprimer votre compte (et donc vos Données Personnelles) directement au sein de votre
          espace membre (pour plus d’informations sur ce processus vous pouvez consulter nos CGU).
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          Après nous avoir contacté, si vous estimez que vos droits ne sont pas respectés, vous
          disposez en outre du droit d’introduire une réclamation auprès d’une autorité de contrôle.
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          L’autorité de contrôle luxembourgeoise est La Commission nationale pour la protection des
          données (CNPD) , située 15, Boulevard du Jazz L-4370 Belvaux
        </Typography>
      </Container>
    </>
  );
}

export default privacyPolicy;
