import React from 'react';
import { Container, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

function recruitmentChart() {
  return (
    <Container disableGutters maxWidth="md" component="main" sx={{ pt: 8, pb: 6 }}>
      <Typography component="h2" color="dark" gutterBottom>
        Charte de recrutement
      </Typography>
      <Typography fontWeight="light" color="dark" fontSize="20px" component="h2" mt={2} mb={2}>
        Service All eyes on me
      </Typography>
      <Typography fontWeight="light" color="dark" fontSize="16px" component="p" mt={2} mb={2}>
        Depuis sa création, la volonté de la société JBBD (« AEOM ») est de mettre en relation
        sociétés et candidat·e·s dans un environnement innovant et valorisant pour tous.
      </Typography>
      <Typography fontWeight="light" color="dark" fontSize="16px" component="p" mt={2} mb={2}>
        A ce titre, AEOM incite les sociétés à garantir aux candidat·e·s une expérience de
        recrutement optimale et éthique, en adhérant à des principes de déontologie et de
        professionnalisme solides.
      </Typography>
      <Typography fontWeight="light" color="dark" fontSize="16px" component="p" mt={2} mb={2}>
        AEOM publie la présente Charte afin d’affirmer les valeurs qu’elle considère comme
        essentielles à la bonne utilisation de ses services, tout en laissant aux sociétés le soin
        de définir leur propre politique de recrutement et le contenu de leurs offres d’emploi, dans
        le respect de la réglementation et des bonnes pratiques.
      </Typography>
      <Typography fontWeight="light" color="dark" fontSize="16px" component="p" mt={2} mb={2}>
        Les principes suivants sont considérés par AEOM comme essentiels à un recrutement
        respectueux des candidat·e·s et valorisant pour les recruteurs :
      </Typography>
      <Typography
        sx={{ ml: { xs: 4, md: 4 }, mt: 2 }}
        fontWeight="light"
        fontSize="16px"
        color="dark"
        component="p"
        mt={2}
        mb={2}
      >
        <li>
          Présenter l’entreprise, le poste à pourvoir, le profil recherché et l’environnement de
          travail de façon complète, transparente et loyale ;
        </li>
        <li>
          Adopter une attitude professionnelle et bienveillante à l’égard de chaque candidat·e et
          respecter les principes de non-discrimination et de l’égalité de traitement en examinant
          toute candidature sur la base exclusive de critères objectifs et non discriminants au sens
          de la réglementation applicable (notamment au titre de l’article L 1132-1 du Code du
          travail) ;
        </li>
        <li>
          Demander à chaque candidat·e :
          <Typography
            sx={{ ml: { xs: 4, md: 4 }, mt: 2 }}
            fontWeight="light"
            color="dark"
            component="p"
            mt={2}
            mb={2}
          >
            <li>
              Les seules informations nécessaires à l’évaluation de ses aptitudes professionnelles
              avec l’emploi proposé ;
            </li>
            <li>
              Son accord écrit avant de procéder à une vérification de diplôme ou de références ;
            </li>
          </Typography>
        </li>
        <li>
          Répondre à l’ensemble des candidatures sur une offre d’emploi dans les meilleurs délais et
          tenir les candidat·e·s informé·e·s de l’évolution du processus de recrutement (notamment à
          l’égard des candidat·e·s non retenu·e·s pour le poste) ;
        </li>
        <li>
          Garantir la confidentialité des échanges et le respect de la réglementation en matière de
          données personnelles et de respect de la vie privée, notamment en informant les
          candidat·e·s de la conservation éventuelle de leur dossier.
        </li>
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        AEOM invite toute société à sensibiliser ses équipes à l’interdiction des discriminations à
        l’embauche en leur communiquant le Guide du recrutement sans discrimintation disponible à
        l’adresse suivante: <br />
        <Link
          style={{
            color: 'blue',
          }}
          to="https://www.csl.lu/fr/publications/le-recrutement-sans-discrimination/"
        >
          https://www.csl.lu/fr/publications/le-recrutement-sans-discrimination/{' '}
        </Link>
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Toute société peut également s’informer sur les obligations en matière de protection des
        données à caractère personnel et de respect de la vie privée qui lui incombent pour tout
        processus de recrutement, à partir du site internet de la CNIL à l’adresse <br />
        <Link
          style={{
            color: 'blue',
          }}
          to="https://www.cnil.fr/fr/le-recrutement-et-la-gestion-du-personnel"
        >
          https://www.cnil.fr/fr/le-recrutement-et-la-gestion-du-personnel.{' '}
        </Link>
      </Typography>
    </Container>
  );
}

export default recruitmentChart;
