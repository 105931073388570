// All Eyes On Me helper functions
import pxToRem from 'assets/theme/functions/pxToRem';

export default {
  defaultProps: {
    fontSize: 'inherit',
  },

  styleOverrides: {
    fontSizeSmall: {
      fontSize: `${pxToRem(20)} !important`,
    },

    fontSizeLarge: {
      fontSize: `${pxToRem(36)} !important`,
    },
  },
};
