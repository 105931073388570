import React from 'react';
import { Button, styled } from '@mui/material';
import PropTypes from 'prop-types';

const CustomButton = ({
  backgroundColor,
  color,
  buttonText,
  heroBtn,
  guideBtn,
  getStartedBtn,
  borderColor,
  fontFamily,
  border,
  borderHover,
  padding,
  ...rest
}) => {
  const CustomButton = styled(Button)(({ theme }) => ({
    backgroundColor: backgroundColor,
    fontFamily: fontFamily,
    color: color,
    borderColor: borderColor,
    fontWeight: '700',
    fontSize: '14px',
    cursor: 'pointer',
    padding: padding ? padding : '0.5rem 1.25rem',
    borderRadius: '0.8rem',
    textTransform: 'none',
    border: border,
    whiteSpace: 'nowrap',
    textAlign: 'center',
    '&:hover': {
      color: borderColor,
      border: borderHover,
    },
    [theme.breakpoints.down('md')]: {
      margin: (heroBtn || getStartedBtn) && theme.spacing(0, 'auto', 3, 'auto'),
      width: (heroBtn || getStartedBtn) && '90%',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: guideBtn && theme.spacing(3),
      width: guideBtn && '90%',
    },
  }));

  return <CustomButton {...rest}>{buttonText}</CustomButton>;
};

// Typechecking props for the CustomButton
CustomButton.propTypes = {
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  buttonText: PropTypes.string,
  heroBtn: PropTypes.string,
  guideBtn: PropTypes.bool,
  getStartedBtn: PropTypes.string,
  borderColor: PropTypes.string,
  fontFamily: PropTypes.string,
  border: PropTypes.string,
  borderHover: PropTypes.string,
  padding: PropTypes.string,
  component: PropTypes.object,
};

export default CustomButton;
