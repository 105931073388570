// All Eyes On Me Base Styles
import colors from 'assets/theme/base/colors';

const { primary } = colors;

export default {
  styleOverrides: {
    root: {
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: primary.main,
        },
      },
    },
  },
};
