import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, styled } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import AEOMAutocompleteRoot from 'Components/ui/AEOMAutocomplete/AEOMAutocompleteRoot';

const AEOMAutocomplete = forwardRef(({ limitTags, title, ...rest }, ref) => {
  return (
    <AEOMAutocompleteRoot
      ref={ref}
      limitTags={limitTags}
      getOptionLabel={(option) => option[title]}
      renderOption={(props, option, { selected }) => (
        <LiStyled {...props}>
          <Checkbox sx={{ pl: 0 }} checked={selected} />
          {option[title]}
        </LiStyled>
      )}
      renderTags={(tags) => {
        const numTags = tags.length;
        const filteredTags = limitTags !== -1 ? tags.slice(0, limitTags) : tags;

        return (
          <TagsContainer>
            {filteredTags.map((option, index) => (
              <>
                <span key={index}>{option[title]}</span>
                {(index < limitTags - 1 || (limitTags === -1 && index < filteredTags.length - 1)) &&
                  numTags > 1 &&
                  ', '}
                {numTags > limitTags && index === limitTags - 1 && (
                  <LimitTag>&nbsp;+{numTags - limitTags}&nbsp;</LimitTag>
                )}
              </>
            ))}
          </TagsContainer>
        );
      }}
      popupIcon={<ExpandMoreIcon />}
      {...rest}
    />
  );
});

const LiStyled = styled('li')`
  padding-left: 8px !important;
  padding-right: 8px !important;
`;

const TagsContainer = styled('span')`
  position: absolute;
  top: 5px;
  left: 0;
  width: inherit;
  max-width: calc(100% - 30px);
  overflow: hidden;
  pointer-events: none;
  white-space: nowrap;
  background-color: #f0f2f5;
  z-index: 2;
`;

const LimitTag = styled('span')`
  position: absolute;
  right: 0;
  background-color: white;
`;

AEOMAutocomplete.defaultProps = {
  limitTags: -1,
};

AEOMAutocomplete.propTypes = {
  limitTags: PropTypes.number,
  title: PropTypes.string.isRequired,
};

export default AEOMAutocomplete;
