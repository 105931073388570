import React from 'react';
import { Box, Grid, styled, Typography, useTheme } from '@mui/material';
import { NavLink } from 'react-router-dom';

import Copyright from 'Components/ui/Copyright';
import FollowUs from 'Components/ui/FollowUs';
import AEOMBox from 'Components/ui/AEOMBox/AEOMBox';
import CustomButton from 'Components/ui/CustomButton';
import logo from 'assets/AEOM-Vertical-Address-Logo-White.png';
import SubscribeInput from './SubscribeInput';

const Footer = () => {
  let theme = useTheme();

  const FooterLink = styled('a')(() => ({
    fontSize: '14px',
    color: '#03003D',
    fontWeight: '300',
    cursor: 'pointer',
    fontFamily: 'Inter',
    textDecoration: 'none', // This removes the underline from the link
    '&:hover': {
      color: '#000',
    },
    '&:visited': {
      color: '#03003D', // This will maintain the color even after the link is visited
    },
  }));

  return (
    <footer>
      <Box sx={{ py: 5, px: { xs: 2, sm: 0 }, backgroundColor: '#FF9B68' }}>
        <Grid container maxWidth="xl" sx={{ mx: 'auto' }}>
          <Grid item xs={12} sm={3}>
            <Typography
              sx={{
                fontSize: '20px',
                color: '#ffffff',
                fontWeight: '700',
                py: 0,
              }}
            >
              <img src={logo} style={{ py: 0, color: 'dark', width: '75%' }} />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3} sx={{ mt: 3 }}>
            <Typography
              sx={{
                fontSize: '20px',
                color: '#ffffff',
                fontWeight: '700',
                mb: 2,
              }}
              gutterBottom
            >
              All eyes on me {/*TK: allEyesOn*/}
            </Typography>
            <FooterLink href="/company/118">Qui sommes-nous {/*TK: aboutUs*/}</FooterLink>
            <br />
            <FooterLink href="/company/118">Offres d&apos;emploi {/*TK: jobOffers*/}</FooterLink>
            <br />
            <FooterLink>Press</FooterLink>
            <br />
            <FooterLink href="https://solutions.alleyesonme.jobs/">Solution</FooterLink>
          </Grid>
          {/* TODO: I want to add vertical spacing between these */}
          <Grid item xs={12} sm={6} sx={{ mt: 3 }}>
            <Typography
              sx={{
                fontSize: '20px',
                color: '#ffffff',
                mb: 2,
              }}
            >
              Newsletter
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: '14px',
                color: '#03003D',
                fontWeight: '500',
                mb: 2,
              }}
            >
              Boostez votre carrière à Luxembourg ! 🚀 Inscrivez-vous à notre newsletter pour des
              conseils sur le bien-être au travail et restez informé des dernières tendances du
              marché.
            </Typography>
            <br />
            <Box sx={{ px: { xs: 2, sm: 0 } }}>
              <SubscribeInput />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#ff9a67',
          marginTop: '0px',

          px: { xs: 2, sm: 0 },
        }}
      >
        <AEOMBox
          display="flex"
          sx={{
            flexDirection: 'row',
          }}
          alignItems="center"
          justifyContent="center"
          gap="1rem"
          flexWrap="wrap"
        >
          <CustomButton
            component={NavLink}
            to="https://solutions.alleyesonme.jobs/"
            borderColor={theme.palette.blue.main}
            color={theme.palette.white.main}
            buttonText="Employeurs"
            fontFamily="Arvo Regular"
            border={'2px solid ' + theme.palette.blue.main}
            borderHover={'2px solid ' + theme.palette.white.main}
            padding={'0.5rem 100px'}
          />
          {/*TK: employers*/}
          <CustomButton
            component={NavLink}
            to="/login"
            borderColor={theme.palette.blue.main}
            backgroundColor={theme.palette.blue.main}
            color={theme.palette.white.main}
            buttonText="Connexion"
            fontFamily="Arvo Regular"
            border={'2px solid ' + theme.palette.blue.main}
            borderHover={'2px solid ' + theme.palette.grey[300]}
            padding={'0.5rem 105px'}
          />
          {/*TK: login*/}
        </AEOMBox>
        <br />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#ff9a67',
          marginTop: '0px',
          pt: 5,
          px: { xs: 2, sm: 0 },
        }}
      >
        <FollowUs />
      </Box>

      <Box sx={{ px: { xs: 2, sm: 0 }, backgroundColor: '#FF9B68' }}>
        <Copyright />
      </Box>
    </footer>
  );
};

export default Footer;
