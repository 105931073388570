import axios from 'axios';
import { setStorage } from './storage';

export const setTokens = (token, refreshToken) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  setStorage('token', token);
  setStorage('refreshToken', refreshToken);
};

export const getUser = async (id) => {
  return await axios.get(`/api/users/${id}`);
};

export const authUser = (data) =>
  axios.post(`/auth`, { email: data.email, password: data.password });

export const authSuccess = (values) => {
  const { token, refresh_token: refreshToken } = values;
  setTokens(token, refreshToken);
};
