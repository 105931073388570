import axios from 'axios';

/**
 * @param {object} data
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const postMediaObject = (data) => {
  return axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/media_objects`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

/**
 * @param {int=} id
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const deleteMediaObject = (id) => {
  return axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/media_objects/${id}`);
};
