// All Eyes On Me base styles
import colors from 'assets/theme/base/colors';
import typography from 'assets/theme/base/typography';

// All Eyes On Me helper functions
import pxToRem from 'assets/theme/functions/pxToRem';

const { blue, primary } = colors;
const { size } = typography;

export default {
  styleOverrides: {
    root: {
      minWidth: pxToRem(160),
      minHeight: 'unset',
      padding: `${pxToRem(4.8)} ${pxToRem(16)}`,
      fontSize: size.sm,
      color: blue.main,
      transition: 'background-color 300ms ease, color 300ms ease',

      '&:focus, &.Mui-selected, &.Mui-selected:focus': {
        backgroundColor: primary.lighter,
      },
      '&:hover, &.Mui-selected:hover': {
        backgroundColor: primary.light,
      },
    },
  },
};
