import colors from '../../base/colors';

const { primary, white } = colors;

export default {
  styleOverrides: {
    root: {
      '&.Mui-selected, &:hover, &.Mui-selected:hover': {
        backgroundColor: primary.main,
        color: white.main,
      },
    },
  },
};
