import React, { useEffect, useState } from 'react';
import {
  Button,
  Typography,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Switch,
  InputAdornment,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { map, omit, pick, isEmpty } from 'ramda';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { createTranslationForCompany } from 'utils/translations';
import { getStorage } from 'utils/storage';
import LoadingComponent from 'Components/LoadingComponent';
import {
  editCompany,
  getCompany,
  getCompanySponsoredContent,
  companyLogo,
  companyBanner,
  deleteCompanyLogo,
  deleteCompanyBanner,
} from 'services/Company';
import { getCompanySizes } from 'services/CompanySize';
import { deleteMediaObject, postMediaObject } from 'services/MediaObject';
import { COMPANY_STATES } from 'constants/company';
import { getIndustries } from '../../../services/Industry';

registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateType);

const schema = yup
  .object({
    name: yup.string().required(),
    address: yup.object({
      addressLine: yup.string().required(),
      city: yup.string().required(),
      postalCode: yup.string().required(),
      country: yup.string().required(),
    }),
  })
  .required();

const EditCompany = () => {
  const [logoImage, setLogoImage] = useState([]);
  const [backgroundImage, setBackgroundImage] = useState([]);
  const [pictures, setPictures] = useState([]);
  const [videos, setVideos] = useState([]);
  const [mediasInDb, setMediasInDb] = useState([]);
  const [companySizeList, setCompanySizeList] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const user = getStorage('user');
  const { control, handleSubmit, register, reset, getValues, setValue } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const { companyId } = useParams();

  let navigate = useNavigate();

  const { mutateAsync: updateCompany } = useMutation({
    mutationFn: (data) => editCompany({ id: data.id, company: data }),
    onSuccess: () => {
      navigate('/admin/companies');
    },
  });

  const { data: companySizes } = useQuery({
    queryKey: ['job-types'],
    queryFn: () => getCompanySizes({ pagination: false, locale: 'fr' }),
    cacheTime: 10000,
  });

  const { data: industries } = useQuery({
    queryKey: ['industries'],
    queryFn: () => getIndustries({ pagination: false, locale: 'fr' }),
    cacheTime: 10000,
  });

  const { isLoading, data: company } = useQuery({
    queryKey: ['company-by-id', companyId],
    queryFn: () => getCompany(companyId),
    cacheTime: 0,
  });

  const { isLoading: isLoadingSponsoredContent } = useQuery({
    queryFn: () => getCompanySponsoredContent({ id: companyId }),
    queryKey: ['company-sc-list'],
    onSuccess: ({ data: scData }) => {
      const { medias } = scData;
      const currentPictures = medias.filter((media) => media.fileType === 'picture');

      setMediasInDb(medias.map((media) => media['@id']));
      setVideos(
        medias
          .filter((media) => media.fileType === 'youtube')
          .map((media) => media.link.replace('https://youtu.be/', ''))
      );
      setPictures(
        currentPictures.map((media) => ({
          source: `${process.env.REACT_APP_API_URL}${media.contentUrl.replace('/media', '/image')}`,
          option: {
            type: 'local',
          },
        }))
      );
    },
  });

  const onSubmit = async (data) => {
    await Promise.all(
      mediasInDb.map((mediaId) => deleteMediaObject(mediaId.split('/media_objects/')[1]))
    );

    const newMediaIds = [];

    await Promise.all(
      pictures.map((media) => {
        const formData = new FormData();

        formData.append('file', media.file);
        formData.append('fileType', 'picture');
        formData.append('companyId', companyId);

        return postMediaObject(formData);
      }),
      videos
        .filter((video) => !!video)
        .map((video) => {
          const formData = new FormData();

          video = video.replace('https://www.youtube.com/watch?v=', '');
          video = video.split('&')[0];

          formData.append('link', `https://youtu.be/${video.replace('https://youtu.be/', '')}`);
          formData.append('fileType', 'youtube');
          formData.append('companyId', companyId);

          return postMediaObject(formData);
        })
    ).then((medias) => {
      medias.forEach((media) => newMediaIds.push(media.data['@id']));
    });

    data.translations = { fr: {} };
    data.translations.fr = createTranslationForCompany(
      'fr',
      data.weAreLookingFor,
      data.description,
      data.citation
    );
    let newData = omit(['citation', 'weAreLookingFor', 'description'], data);
    newData.medias = newMediaIds;

    if (logoImage.length > 0) {
      if (logoImage[0].file instanceof File) {
        const imageData = new FormData();
        imageData.append('file', logoImage[0].file);
        imageData.append('companyId', newData.id);

        if (newData.logo) {
          await deleteCompanyLogo(newData.logo.id);
        }

        await companyLogo(imageData).then((response) => {
          newData.logo = response.data['@id'];
        });
      } else {
        newData.logo = newData.logo['@id'];
      }
    } else if (newData.logo) {
      await deleteCompanyLogo(newData.logo.id);
      newData = omit(['logo'], newData);
    }

    if (backgroundImage.length > 0) {
      if (backgroundImage[0].file instanceof File) {
        const imageData = new FormData();
        imageData.append('file', backgroundImage[0].file);
        imageData.append('companyId', newData.id);

        if (newData.banner) {
          await deleteCompanyBanner(newData.banner.id);
        }

        await companyBanner(imageData).then((response) => {
          newData.banner = response.data['@id'];
        });
      } else {
        newData.banner = newData.banner['@id'];
      }
    } else if (newData.banner) {
      await deleteCompanyBanner(newData.banner.id);
      newData = omit(['banner'], newData);
    }

    if (isEmpty(newData.industry)) {
      newData = omit(['industry'], newData);
    }

    if (isEmpty(newData.size)) {
      newData = omit(['size'], newData);
    }

    await updateCompany(newData);
  };

  useEffect(() => {
    if (company) {
      const companyData = company?.data;
      reset(companyData);

      setValue('industry', companyData?.industry?.['@id']);

      if (getValues()?.logo) {
        setLogoImage([
          {
            source: `${process.env.REACT_APP_API_URL}/remote-image/${getValues().logo.filePath}`,
            option: {
              type: 'local',
            },
          },
        ]);
      }

      if (getValues()?.banner) {
        setBackgroundImage([
          {
            source: `${process.env.REACT_APP_API_URL}/remote-image/${getValues().banner.filePath}`,
            option: {
              type: 'local',
            },
          },
        ]);
      }
    }
  }, [company]);

  useEffect(() => {
    if (companySizes?.data['hydra:member'].length > 0) {
      const values = companySizes?.data['hydra:member'];
      const pickedValues = map((item) => pick(['@id', 'name'], item), values);
      setCompanySizeList(pickedValues);
    }
  }, [companySizes]);

  useEffect(() => {
    if (industries?.data['hydra:member'].length > 0) {
      const values = industries?.data['hydra:member'];
      const pickedValues = map((item) => pick(['@id', 'cname'], item), values);
      setIndustryList(pickedValues);
    }
  }, [industries]);

  if (isLoading || isLoadingSponsoredContent) {
    return <LoadingComponent />;
  }

  return (
    <Grid
      container
      rowSpacing={2.5}
      columnSpacing={2}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      maxWidth="xl"
      sx={{ py: 5, mx: 'auto' }}
    >
      <Grid item xs={12}>
        <Typography variant="h4">Company</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Logo</Typography>
      </Grid>
      <Grid item xs={12}>
        <FilePond
          files={logoImage}
          onupdatefiles={setLogoImage}
          name="logoImage"
          labelIdle='Drag & Drop your file or <span class="filepond--label-action">Browse</span>'
          credits={false}
          acceptedFileTypes={['image/png', 'image/jpeg']}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Bannière</Typography>
      </Grid>
      <Grid item xs={12}>
        <FilePond
          files={backgroundImage}
          onupdatefiles={setBackgroundImage}
          name="backgroundImage"
          labelIdle='Drag & Drop your file or <span class="filepond--label-action">Browse</span>'
          credits={false}
          acceptedFileTypes={['image/png', 'image/jpeg']}
        />
      </Grid>
      <Grid
        item
        container
        xs={12}
        columnSpacing={2}
        alignItems="end"
        justifyContent="space-between"
      >
        <Grid item xs={12} sm>
          <Controller
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                fullWidth
                {...field}
                id="companyName"
                label="Nom de l'entreprise"
                defaultValue=""
                helperText={error ? error.message : null}
                error={!!error}
              />
            )}
            name="name"
          />
        </Grid>

        {user.roles.some((role) => role === 'ROLE_ADMIN') && (
          <>
            <Grid item xs={12} sm={2} alignContent="space-around" justifyContent="space-around">
              <InputLabel htmlFor="statusLabel" sx={{ mb: 0.75 }}>
                Status
              </InputLabel>
              <Controller
                control={control}
                name="state"
                defaultValue=""
                render={({ field: { onChange, value, ref } }) => (
                  <Select
                    fullWidth
                    onChange={onChange}
                    value={value}
                    inputRef={ref}
                    labelId="statusLabel"
                  >
                    {COMPANY_STATES.map((item, index) => (
                      <MenuItem value={item} key={`state-${index}`}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={2} alignContent="space-around" justifyContent="space-around">
              <InputLabel htmlFor="statusLabel" sx={{ mb: 0.75 }}>
                Sponsorisé
              </InputLabel>
              <Controller
                control={control}
                name="isSponsored"
                defaultValue=""
                render={({ field: { onChange, value, ref } }) => (
                  <Switch checked={value} onChange={onChange} inputRef={ref} />
                )}
              />
            </Grid>
          </>
        )}
      </Grid>
      {getValues('isSponsored') && (
        <Grid item xs={12}>
          <TextField
            multiline
            {...register('description')}
            label="A propos de l'entreprise"
            id="companyDescription"
            defaultValue=""
            minRows={4}
            fullWidth
            sx={{ resize: 'both' }}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          multiline
          {...register('weAreLookingFor')}
          label="A propos de l'équipe"
          id="companyLookingFor"
          defaultValue=""
          minRows={4}
          fullWidth
          sx={{ resize: 'both' }}
        />
      </Grid>
      {getValues('isSponsored') && (
        <>
          <Grid item xs={8}>
            <TextField
              multiline
              {...register('citation')}
              label="Citation"
              id="citation"
              defaultValue=""
              minRows={2}
              fullWidth
              sx={{ resize: 'both' }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              {...register('companyOwner')}
              label="Author"
              id="citationAuthor"
              defaultValue=""
              fullWidth
              sx={{ resize: 'both' }}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <InputLabel htmlFor="companySizeLabel" sx={{ mb: 0.75 }}>
          Taille de l&apos;entreprise
        </InputLabel>
        <Controller
          control={control}
          name="size"
          defaultValue=""
          render={({ field: { onChange, value, ref } }) => (
            <Select
              fullWidth
              onChange={onChange}
              value={value}
              inputRef={ref}
              labelId="companySizeLabel"
            >
              {companySizeList.map((type) => (
                <MenuItem value={type['@id']} key={type['@id']}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <InputLabel htmlFor="companySizeLabel" sx={{ mb: 0.75 }}>
          Catégorie
        </InputLabel>
        <Controller
          control={control}
          name="industry"
          defaultValue=""
          render={({ field: { onChange, value, ref } }) => (
            <Select
              fullWidth
              onChange={onChange}
              value={value}
              inputRef={ref}
              labelId="industryLabel"
            >
              {industryList.map((industry) => (
                <MenuItem value={industry['@id']} key={industry['@id']}>
                  {industry.cname}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          {...register('websiteUrl')}
          label="Site internet"
          id="websiteUrl"
          defaultValue=""
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Réseaux sociaux</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          {...register('facebook')}
          type="text"
          id="facebook"
          label="Facebook"
          defaultValue=""
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          {...register('instagram')}
          type="text"
          id="instagram"
          label="Instagram"
          defaultValue=""
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          {...register('linkedIn')}
          type="text"
          id="linkedin"
          label="LinkedIn"
          defaultValue=""
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          {...register('tikTok')}
          type="text"
          id="tikTok"
          label="TikTok"
          defaultValue=""
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Location</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              fullWidth
              {...field}
              type="text"
              id="addressLine"
              label="Adresse"
              defaultValue=""
              helperText={error ? error.message : null}
              error={!!error}
            />
          )}
          name="address.addressLine"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              fullWidth
              {...field}
              id="city"
              label="Ville"
              defaultValue=""
              helperText={error ? error.message : null}
              error={!!error}
            />
          )}
          name="address.city"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              fullWidth
              {...field}
              id="postalCode"
              label="Code postal"
              defaultValue=""
              helperText={error ? error.message : null}
              error={!!error}
            />
          )}
          name="address.postalCode"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              fullWidth
              {...field}
              id="country"
              label="Pays"
              defaultValue=""
              helperText={error ? error.message : null}
              error={!!error}
            />
          )}
          name="address.country"
        />
      </Grid>
      {getValues('isSponsored') && (
        <>
          <Grid item xs={12}>
            <Typography variant="h6">Illustrations (maximum 20)</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <FilePond
              allowMultiple
              allowReorder
              maxFiles={20}
              files={pictures}
              onupdatefiles={setPictures}
              onreorderfiles={setPictures}
              name="sponsoredMedia"
              labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
              credits={false}
              acceptedFileTypes={['image/png', 'image/jpeg']}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Vidéos (YouTube seulement)</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              defaultValue={videos[0]}
              onChange={(e) =>
                setVideos((videos) => {
                  const newVideos = [...videos];
                  newVideos[0] = e.currentTarget.value;
                  return newVideos;
                })
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ fontSize: '0.875rem' }}>
                    https://youtu.be/
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              defaultValue={videos[1]}
              onChange={(e) =>
                setVideos((videos) => {
                  const newVideos = [...videos];
                  newVideos[1] = e.currentTarget.value;
                  return newVideos;
                })
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ fontSize: '0.875rem' }}>
                    https://youtu.be/
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              defaultValue={videos[2]}
              onChange={(e) =>
                setVideos((videos) => {
                  const newVideos = [...videos];
                  newVideos[2] = e.currentTarget.value;
                  return newVideos;
                })
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ fontSize: '0.875rem' }}>
                    https://youtu.be/
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              defaultValue={videos[3]}
              onChange={(e) =>
                setVideos((videos) => {
                  const newVideos = [...videos];
                  newVideos[3] = e.currentTarget.value;
                  return newVideos;
                })
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ fontSize: '0.875rem' }}>
                    https://youtu.be/
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              defaultValue={videos[4]}
              onChange={(e) =>
                setVideos((videos) => {
                  const newVideos = [...videos];
                  newVideos[4] = e.currentTarget.value;
                  return newVideos;
                })
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ fontSize: '0.875rem' }}>
                    https://youtu.be/
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Button variant="contained" sx={{ width: '100%' }} type="submit">
          Enregistrer
        </Button>
      </Grid>
    </Grid>
  );
};

export default EditCompany;
