import React, { useState } from 'react';
import { Alert, Avatar, TextField, Box, Typography, Container } from '@mui/material';
import KeyIcon from '@mui/icons-material/Key';
import { useForm } from 'react-hook-form';

import AEOMButton from 'Components/ui/AEOMButton';
import { requestResetPassword } from 'services/User';

function Request() {
  const [alert, setAlert] = useState();

  const { handleSubmit, register } = useForm({
    mode: 'all',
  });

  const onSubmit = async (data) => {
    requestResetPassword(data.email)
      .then((response) => {
        if (response?.response?.status === 400) {
          setAlert({
            severity: 'error',
            message: "Veuillez vérifier l'email que vous nous avez fournis",
          });
        } else {
          setAlert({
            severity: 'success',
            message:
              "Un email avec le lien pour réinitialiser votre mot de passe vient d'être envoyé",
          });
        }
      })
      .catch(() => {
        setAlert({
          severity: 'error',
          message: 'Une erreur est survenue, veuillez réessayer',
        });
      });
  };

  return (
    <>
      <Container
        maxWidth="md"
        sx={{ py: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <KeyIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Mot de passe oublié
        </Typography>

        {alert && (
          <Alert
            variant="filled"
            severity={alert.severity}
            sx={{ mt: 3, width: '100%' }}
            onClose={() => setAlert(null)}
          >
            {alert.message}
          </Alert>
        )}

        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3, width: '100%' }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            {...register('email')}
            autoComplete="email"
            autoFocus
          />

          <AEOMButton color="primary" type="submit" sx={{ width: '100%', mt: 3, mb: 2 }}>
            Envoyer
          </AEOMButton>
        </Box>
      </Container>
    </>
  );
}

export default Request;
