import React, { useState, useEffect } from 'react';
import { InputLabel, Typography, MenuItem, Select, TextField, Grid, Switch } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { map, omit, pick } from 'ramda';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as yup from 'yup';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { yupResolver } from '@hookform/resolvers/yup';

import { createJob } from 'services/Job';
import AEOMButton from 'Components/ui/AEOMButton';
import { createTranslation } from 'utils/translations';
import { getContractTypes } from 'services/ContractType';
import { getDegrees } from 'services/Degree';
import { getJobTypes } from 'services/JobType';
import { REQUIRED } from 'constants/form';

const schema = yup.object({
  title: yup.string().required(REQUIRED),
  isVisible: yup.boolean(),
  contractType: yup.string(),
  type: yup.string(),
  startingDate: yup.date(),
  salaryLow: yup.string(),
  salaryHigh: yup.string(),
  addressLine: yup.string(),
  city: yup.string(),
  postalCode: yup.string(),
  country: yup.string(),
  degree: yup.string(),
  offerUrl: yup.string().required(REQUIRED),
  description: yup.string().required(REQUIRED),
  lookingFor: yup.string(),
  mission: yup.string(),
});

const CreateJob = () => {
  const [contractTypeList, setContractTypeList] = useState([]);
  const [degreeTypeList, setDegreeTypeList] = useState([]);
  const [jobTypeList, setJobTypeList] = useState([]);
  const { control, handleSubmit, register } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const {
    state: { companyId },
  } = useLocation();

  const { data: contractTypes } = useQuery({
    queryKey: ['contract-types'],
    queryFn: () => getContractTypes({ pagination: false, locale: 'fr' }),
    cacheTime: 10000,
  });

  const { data: jobTypes } = useQuery({
    queryKey: ['job-types'],
    queryFn: () => getJobTypes({ pagination: false, locale: 'fr' }),
    cacheTime: 10000,
  });

  const { data: degreeTypes } = useQuery({
    queryKey: ['degrees'],
    queryFn: () => getDegrees({ pagination: false, locale: 'fr' }),
    cacheTime: 10000,
  });

  const { mutateAsync: addJob } = useMutation({
    mutationFn: (data) => createJob(data),
    onSuccess: () => {
      navigate('/admin/jobs');
    },
  });

  useEffect(() => {
    if (!companyId) {
      navigate('/admin/jobs');
    }
  }, [companyId]);

  useEffect(() => {
    if (contractTypes?.data['hydra:member'].length > 0) {
      const values = contractTypes?.data['hydra:member'];
      const pickedValues = map((item) => pick(['@id', '@type', 'code', 'name'], item), values);
      setContractTypeList(pickedValues);
    }
  }, [contractTypes]);

  useEffect(() => {
    if (jobTypes?.data['hydra:member'].length > 0) {
      const values = jobTypes?.data['hydra:member'];
      const pickedValues = map((item) => pick(['@id', '@type', 'code', 'name'], item), values);
      setJobTypeList(pickedValues);
    }
  }, [jobTypes]);

  useEffect(() => {
    if (degreeTypes?.data['hydra:member'].length > 0) {
      const values = degreeTypes?.data['hydra:member'];
      const pickedValues = map((item) => pick(['@id', '@type', 'code', 'name'], item), values);
      setDegreeTypeList(pickedValues);
    }
  }, [degreeTypes]);

  const onSubmit = async (data) => {
    data.company = `/api/companies/${companyId}`;
    data.translations = { fr: {} };
    data.translations.fr = createTranslation(
      'fr',
      data.title,
      data.mission,
      data.lookingFor,
      data.description
    );

    let newData = omit(['title', 'mission', 'lookingFor', 'description'], data);

    if (newData.contractType === '') {
      newData.contractType = null;
    }

    if (newData.type === '') {
      newData.type = null;
    }

    if (newData.degree === '') {
      newData.degree = null;
    }

    await addJob(newData);
  };

  return (
    <Grid
      container
      rowSpacing={2.5}
      columnSpacing={2}
      component="form"
      maxWidth="xl"
      onSubmit={handleSubmit(onSubmit)}
      sx={{ py: 5, mx: 'auto' }}
    >
      <Grid item xs={12}>
        <Typography variant="h4">Job</Typography>
      </Grid>
      <Grid item xs={12} sm={10}>
        <Controller
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              fullWidth
              {...field}
              id="jobTitle"
              label="Titre"
              defaultValue=""
              helperText={error ? error.message : null}
              error={!!error}
            />
          )}
          name="title"
        />
      </Grid>
      <Grid item xs={12} sm={2} alignContent="space-around" justifyContent="space-around">
        <Controller
          control={control}
          name="isVisible"
          defaultValue={false}
          render={({ field: { onChange, value } }) => (
            <Switch onChange={onChange} checked={value} />
          )}
        />
        <Typography variant="button" textTransform="inherit">
          Visible
        </Typography>
      </Grid>
      <Grid
        item
        container
        xs={12}
        columnSpacing={2}
        alignItems="end"
        justifyContent="space-between"
      >
        <Grid item xs={12} sm={5}>
          <InputLabel htmlFor="contractTypeLabel" sx={{ mb: 0.75 }}>
            Type de contract
          </InputLabel>
          <Controller
            control={control}
            name="contractType"
            defaultValue=""
            render={({ field: { onChange, value, ref } }) => (
              <Select
                fullWidth
                onChange={onChange}
                value={value}
                inputRef={ref}
                labelId="contractTypeLabel"
              >
                <MenuItem value="">-</MenuItem>
                {contractTypeList.map((type) => (
                  <MenuItem value={type['@id']} key={type['@id']}>
                    {type.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <InputLabel htmlFor="jobTypeLabel" sx={{ mb: 0.75 }}>
            Type d&apos;emploi
          </InputLabel>
          <Controller
            control={control}
            name="type"
            defaultValue=""
            render={({ field: { onChange, value, ref } }) => (
              <Select
                fullWidth
                onChange={onChange}
                value={value}
                inputRef={ref}
                labelId="jobTypeLabel"
              >
                <MenuItem value="">-</MenuItem>
                {jobTypeList.map((type) => (
                  <MenuItem value={type['@id']} key={type['@id']}>
                    {type.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Controller
            control={control}
            name="startingDate"
            defaultValue={dayjs(new Date())}
            render={({ field: { onChange, value, ref } }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                <DatePicker
                  label="Date de début"
                  value={value}
                  inputRef={ref}
                  defaultValue={dayjs(new Date())}
                  onChange={(date) => {
                    if (dayjs(date).isValid) onChange(dayjs(date).toISOString());
                  }}
                  sx={{ width: '100%' }}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6">Tranche salarial</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField {...register('salaryLow')} fullWidth id="salaryLow" defaultValue="" label="De" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          {...register('salaryHigh')}
          fullWidth
          id="salaryHigh"
          defaultValue=""
          label="A"
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6">Adresse</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          {...register('location.addressLine')}
          fullWidth
          type="text"
          id="addressLine"
          defaultValue=""
          label="Rue + Numéro"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          {...register('location.city')}
          fullWidth
          type="text"
          id="city"
          defaultValue=""
          label="Ville"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          {...register('location.postalCode')}
          fullWidth
          type="text"
          id="postalCode"
          defaultValue=""
          label="Code postal"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          {...register('location.country')}
          fullWidth
          type="text"
          id="country"
          defaultValue=""
          label="Pays"
        />
      </Grid>

      <Grid
        item
        container
        xs={12}
        alignItems="end"
        alignContent="end"
        justifyContent="space-between"
        rowSpacing={2.5}
        columnSpacing={2}
      >
        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor="degreeLabel" sx={{ mb: 0.75 }}>
            Niveau d&apos;études
          </InputLabel>
          <Controller
            control={control}
            name="degree"
            defaultValue=""
            render={({ field: { onChange, value, ref } }) => (
              <Select
                id="degree"
                onChange={onChange}
                value={value}
                inputRef={ref}
                labelId="degreeLabel"
                sx={{ width: '100%', height: 45 }}
              >
                <MenuItem value="">-</MenuItem>
                {degreeTypeList.map((degree) => (
                  <MenuItem value={degree['@id']} key={degree.code}>
                    {degree.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                fullWidth
                {...field}
                id="offerUrl"
                label="Lien de l'offre"
                defaultValue=""
                helperText={error ? error.message : null}
                error={!!error}
              />
            )}
            name="offerUrl"
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              multiline
              minRows={4}
              fullWidth
              {...field}
              id="jobDesc"
              label="Description"
              defaultValue=""
              helperText={error ? error.message : null}
              error={!!error}
              sx={{ resize: 'both' }}
            />
          )}
          name="description"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          multiline
          {...register('lookingFor')}
          type="text"
          label="Nous recherchons ..."
          id="lookingFor"
          defaultValue=""
          minRows={4}
          fullWidth
          sx={{ resize: 'both' }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          multiline
          {...register('mission')}
          type="text"
          label="Mission"
          id="mission"
          defaultValue=""
          minRows={4}
          fullWidth
          sx={{ resize: 'both' }}
        />
      </Grid>
      <Grid item xs={12}>
        <AEOMButton color="primary" type="submit" sx={{ width: '100%' }}>
          Enregistrer
        </AEOMButton>
      </Grid>
    </Grid>
  );
};

export default CreateJob;
