import React, { useState } from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { Button, InputAdornment, TextField, Typography } from '@mui/material';
import { isEmpty } from 'ramda';

const SubscribeInput = () => {
  const [email, setEmail] = useState('');
  const postUrl = `https://alleyesonme.us21.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;

  return (
    <>
      <MailchimpSubscribe
        url={postUrl}
        render={({ subscribe, status, message }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (!isEmpty(email)) {
                subscribe({ MERGE0: email });
              }
            }}
          >
            {status === 'sending' && (
              <div>
                <Typography>Inscription en cours ...</Typography>
              </div>
            )}
            {status === 'success' && (
              <div>
                <Typography>Vous êtes bien inscrit à notre newsletter</Typography>
              </div>
            )}
            {status === 'error' && <div dangerouslySetInnerHTML={{ __html: message }} />}
            <TextField
              sx={{
                fieldset: {
                  borderColor: '05FF8F !important',
                },
                '& .MuiInputBase-root': {
                  paddingRight: 0,
                  background: 'white',
                  borderRadius: '16px',
                },
                height: '100%',
                paddingRight: 2,
              }}
              fullWidth
              color="white"
              placeholder="e-mail"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    sx={{
                      height: '100%',
                    }}
                    position="end"
                  >
                    <Button
                      variant="solid"
                      type="submit"
                      sx={{
                        color: '#fff',
                        backgroundColor: '#05FF8F',
                        height: '100%',
                        margin: 0,
                        paddingX: '24px',
                        paddingY: '12px',
                        fontSize: '14px',
                        fontFamily: 'Arvo Regular',
                        flex: 1,
                        '&:hover': {
                          backgroundColor: '#0ad077',
                        },
                        borderTopRightRadius: '16px',
                        borderBottomRightRadius: '16px',
                        borderTopLeftRadius: '0',
                        borderBottomLeftRadius: '0',
                      }}
                      disabled={!!status}
                    >
                      S&apos;abonner
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </form>
        )}
      />
    </>
  );
};

export default SubscribeInput;
