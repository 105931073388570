import React, { useState } from 'react';
import {
  Avatar,
  Container,
  Typography,
  Box,
  Grid,
  TextField,
  Alert,
  FormControlLabel,
  Checkbox,
  Button,
  FormHelperText,
  FormControl,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from 'axios';

import AEOMButton from 'Components/ui/AEOMButton';
import { REQUIRED } from 'constants/form';

const schema = yup
  .object({
    company: yup.string().required(REQUIRED),
    firstName: yup.string().required(REQUIRED),
    lastName: yup.string().required(REQUIRED),
    email: yup.string().email().required(REQUIRED),
    plainPassword: yup
      .string()
      .min(8)
      .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/)
      .required(REQUIRED),
    cgu: yup
      .boolean()
      .test('singleCheckbox', 'Veuillez accepter nos CGU pour vous inscrire', (val) => val),
  })
  .required();

function BecomeClient() {
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState();
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = (data) => {
    setIsLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/users`, data)
      .then((response) => {
        if (response?.response?.status === 409) {
          if (response.response.data === 'User already exist') {
            setAlert({
              message:
                "L'adresse saisie existe déjà dans le système, utilisez le menu connexion pour accéder à l'interface d'administration de votre entreprise",
              severity: 'error',
            });
          } else {
            setAlert({
              message:
                'L’entreprise que vous souhaitez gérer a déjà rejoint notre site. Veuillez vous rapprocher de votre administrateur afin qu’il puisse vous autoriser l’accès.',
              severity: 'error',
            });
          }
        } else {
          setAlert({
            message:
              'Un email vient de vous être envoyé afin de confirmer votre adresse. Merci de cliquer sur le lien pour pouvoir activer votre compte.',
            severity: 'success',
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 409) {
          if (error.response.data === 'User already exist') {
            setAlert({
              message:
                "L'adresse saisie existe déjà dans le système, utilisez le menu connexion pour accéder à l'interface d'administration de votre entreprise",
              severity: 'error',
            });
          } else {
            setAlert({
              message:
                'L’entreprise que vous souhaitez gérer a déjà rejoint notre site. Veuillez vous rapprocher de votre administrateur afin qu’il puisse vous autoriser l’accès.',
              severity: 'error',
            });
          }
        } else {
          setAlert({
            message: 'Erreur serveur',
            severity: 'error',
          });
        }
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <Container
        maxWidth="md"
        sx={{ py: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Devenir client
        </Typography>

        {alert && (
          <Alert
            onClose={() => setAlert(null)}
            variant="filled"
            severity={alert.severity}
            sx={{ mt: 3 }}
          >
            {alert.message}
          </Alert>
        )}

        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    fullWidth
                    label="Nom de l'entreprise"
                    autoFocus
                    helperText={error ? error.message : null}
                    error={!!error}
                    {...field}
                  />
                )}
                name="company"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    fullWidth
                    label="Prénom"
                    helperText={error ? error.message : null}
                    error={!!error}
                    {...field}
                  />
                )}
                name="firstName"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    fullWidth
                    label="Nom de famille"
                    helperText={error ? error.message : null}
                    error={!!error}
                    {...field}
                  />
                )}
                name="lastName"
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    fullWidth
                    label="Adresse e-mail"
                    helperText={error ? error.message : null}
                    error={!!error}
                    {...field}
                  />
                )}
                name="email"
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Mot de passe"
                    helperText={error ? error.message : null}
                    error={!!error}
                    type="password"
                  />
                )}
                name="plainPassword"
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl error={!!error}>
                    <FormControlLabel
                      control={<Checkbox {...field} />}
                      label={
                        <div>
                          J&apos;accepte les{' '}
                          <Button
                            component="a"
                            target="_blank"
                            href="/terms"
                            sx={{ p: 0, minWidth: 'inherit' }}
                          >
                            CGU
                          </Button>
                        </div>
                      }
                    />
                    {error && <FormHelperText>{error.message}</FormHelperText>}
                  </FormControl>
                )}
                name="cgu"
              />
            </Grid>
          </Grid>

          <AEOMButton color="primary" sx={{ mt: 3, mb: 2 }} type="submit" disabled={isLoading}>
            Rejoindre All eyes on me
          </AEOMButton>
        </Box>
      </Container>
    </>
  );
}

export default BecomeClient;
