/**
 * @param {URLSearchParams} params
 * @returns {URLSearchParams}
 */
export const cleanUrlSearchParams = (params) => {
  let keysfordel = [];

  params.forEach((value, key) => {
    if (value === '' || value === undefined || value === 'undefined') {
      keysfordel.push(key);
    }
  });

  keysfordel.forEach((key) => {
    params.delete(key);
  });

  return params;
};

export const createSlug = (string) => {
  let slug = string.toLowerCase();
  slug = slug.replaceAll(' ', '_');
  slug = slug.normalize();

  return slug;
};
