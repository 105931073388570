import React, { useState } from 'react';
import { Alert, Avatar, TextField, Box, Typography, Container } from '@mui/material';
import KeyIcon from '@mui/icons-material/Key';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import AEOMButton from 'Components/ui/AEOMButton';
import { resetPassword } from 'services/User';

const schema = yup
  .object({
    plainPassword: yup
      .string()
      .min(8)
      .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/)
      .required(),
  })
  .required();

function Request() {
  const [alert, setAlert] = useState();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const { handleSubmit, control } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    resetPassword({ ...data, token })
      .then((response) => {
        if (response?.status === 200) {
          setAlert({
            severity: 'success',
            message:
              'Votre mot de passe a bien été modifié. Vous pouvez dès à présent vous connecter',
          });
        } else {
          setAlert({
            severity: 'error',
            message: 'Une erreur est survenue, veuillez réessayer',
          });
        }
      })
      .catch(() => {
        setAlert({
          severity: 'error',
          message: 'Une erreur est survenue, veuillez réessayer',
        });
      });
  };

  return (
    <>
      <Container
        maxWidth="md"
        sx={{ py: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <KeyIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Réinitialiser mon mot de passe
        </Typography>

        {alert && (
          <Alert
            variant="filled"
            severity={alert.severity}
            sx={{ mt: 3, width: '100%' }}
            onClose={() => setAlert(null)}
          >
            {alert.message}
          </Alert>
        )}

        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3, width: '100%' }}>
          <Controller
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                autoFocus
                fullWidth
                label="Nouveau mot de passe"
                helperText={error ? error.message : null}
                error={!!error}
                type="password"
              />
            )}
            name="plainPassword"
          />

          <AEOMButton color="primary" type="submit" sx={{ width: '100%', mt: 3, mb: 2 }}>
            Modifier mon mot de passe
          </AEOMButton>
        </Box>
      </Container>
    </>
  );
}

export default Request;
