import React from 'react';
import { Box, Grid, styled, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const Copyright = () => {
  const CopyrightBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2, 0, 2, 0),
    },
  }));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#ff9a67',
      }}
    >
      <Grid container maxWidth="xl" my={5}>
        <CopyrightBox sx={{ mr: 'auto' }}>
          <Typography
            variant="body2"
            sx={{
              fontSize: '14px',
              color: '#03003D',
              padding: '0px 10px 0px',
            }}
          >
            {' © '} {new Date().getFullYear()} {'JBBD SARL'}
          </Typography>
        </CopyrightBox>

        <CopyrightBox>
          <Box
            sx={{
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="body2"
              sx={{
                fontSize: '14px',
                color: '#03003D',
                padding: '0px 10px 0px',
              }}
            >
              <Link
                to="/terms"
                target="_blank"
                style={{
                  textDecoration: 'none',
                  color: '#03003D',
                }}
              >
                Termes et Conditions {/*TK: termsAndConditions*/}
              </Link>
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: '14px',
                color: '#03003D',
                padding: '0px 10px 0px',
              }}
            >
              <Link
                to="/privacy"
                target="_blank"
                style={{
                  textDecoration: 'none',
                  color: '#03003D',
                }}
              >
                Politique de confidentialité {/*TK: privacyPolicy*/}
              </Link>
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: '14px',
                color: '#03003D',
                padding: '0px 10px 0px',
              }}
            >
              <Link
                to="/cookies"
                target="_blank"
                style={{
                  textDecoration: 'none',
                  color: '#03003D',
                }}
              >
                Gestion des cookies {/*TK: cookiesPolicy*/}
              </Link>
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: '14px',
                color: '#03003D',
                padding: '0px 10px 0px',
              }}
            >
              <Link
                to="/charte"
                target="_blank"
                style={{
                  textDecoration: 'none',
                  color: '#03003D',
                }}
              >
                Charte de recrutement {/*TK: recruitmentChart*/}
              </Link>
            </Typography>
          </Box>
        </CopyrightBox>
      </Grid>
    </Box>
  );
};

export default Copyright;
