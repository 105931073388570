import axios from 'axios';

import { cleanUrlSearchParams } from 'utils/dataConvertor';

/**
 * @param {number=} itemsPerPage
 * @param {number=} page
 * @param {boolean=} pagination
 * @param {string=} locale
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
const getTrends = async ({ page, pagination, locale, itemsPerPage }) => {
  let params = new URLSearchParams({
    page,
    pagination,
    locale,
    itemsPerPage,
  });

  params = cleanUrlSearchParams(params).toString();

  return await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/trends?${params}`);
};

/**
 * @param {object} data
 * @param {string=} token
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
const postTrend = async ({ data, token }) => {
  return await axios.post(`${process.env.REACT_APP_API_URL}/api/trends`, data, {
    ...(token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {}),
  });
};

/**
 * @param {number} id
 * @param {object} data
 * @param {AbortSignal=} signal
 * @param {string=} token
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
const updateTrend = async ({ id, data, signal, token }) => {
  return await axios.put(`${process.env.REACT_APP_API_URL}/api/trends/${id}`, data, {
    signal,
    ...(token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {}),
  });
};

/**
 * @param {number} id
 * @param {string=} token
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
const deleteTrend = async ({ id, token }) => {
  return await axios.delete(`${process.env.REACT_APP_API_URL}/api/trends/${id}`, {
    ...(token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {}),
  });
};

export { getTrends, updateTrend, deleteTrend, postTrend };
