import axios from 'axios';

import { cleanUrlSearchParams } from 'utils/dataConvertor';

/**
 * @param {number} id
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
const getCompany = async (id) => {
  id = id || '';
  try {
    return await axios.get(`/api/companies/${id}`);
  } catch (error) {
    // console.log('Error while calling getCompany api ', error);
  }
};

/**
 * @param {string=} name
 * @param {number=} itemsPerPage
 * @param {number=} page
 * @param {boolean=} pagination
 * @param {string=} locale
 * @param {number=} companySize
 * @param {[string]=} industries
 * @param {AbortSignal=} signal
 * @param {string=} state
 * @param {object=} order
 * @param {boolean=} front
 * @param {string=} token
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
const getCompanies = async ({
  page,
  pagination,
  locale,
  itemsPerPage,
  name,
  industries,
  companySize,
  signal,
  order,
  front,
  token,
}) => {
  let params = new URLSearchParams({
    page,
    pagination,
    locale,
    itemsPerPage,
    name,
    front,
    'size.id': companySize,
  });

  if (order) {
    for (const paramsKey in order) {
      params.append(`order[${paramsKey}]`, order[paramsKey]);
    }
  }

  if (industries) {
    industries.forEach((industry) => {
      params.append('industry.code[]', industry);
    });
  }

  params = cleanUrlSearchParams(params).toString();

  return await axios.get(`${process.env.REACT_APP_API_URL}/api/companies?${params}`, {
    signal,
    ...(token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {}),
  });
};

/**
 * @param {number} id
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
const getCompanySponsoredContent = async ({ id }) => {
  return await axios.get(`${process.env.REACT_APP_API_URL}/api/companies/${id}/sponsored_content`);
};

/**
 * @param {number} id
 * @param {object} company
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
const editCompany = async ({ id, company }) => {
  return await axios.put(`${process.env.REACT_APP_API_URL}/api/companies/${id}`, company);
};

/**
 * @param {object} data
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
const companyLogo = async (data) => {
  return await axios.post(`${process.env.REACT_APP_API_URL}/api/company_logo_media_objects`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const deleteCompanyLogo = async (id) => {
  return await axios.delete(`/api/company_logo_media_objects/${id}`);
};

/**
 * @param {object} data
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
const companyBanner = async (data) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/api/company_banner_media_objects`,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
};

const deleteCompanyBanner = async (id) => {
  return await axios.delete(`/api/company_banner_media_objects/${id}`);
};

const deleteCompany = async (id) => {
  return await axios.delete(`/api/companies/${id}`);
};

export {
  getCompany,
  getCompanies,
  getCompanySponsoredContent,
  editCompany,
  companyLogo,
  deleteCompanyLogo,
  companyBanner,
  deleteCompanyBanner,
  deleteCompany,
};
