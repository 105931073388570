import React, { useState } from 'react';
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Button,
  Container,
  Pagination,
  CircularProgress,
  Modal,
  Typography,
  Box,
  Stack,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { format } from 'date-fns';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';

import { getStorage } from 'utils/storage';
import { getCompanies, deleteCompany } from 'services/Company';

const CompanyList = () => {
  const token = getStorage('token');
  const [page, setPage] = useState(1);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [companyIdToDelete, setCompanyIdToDelete] = useState();

  const { error, isLoading, data, refetch } = useQuery({
    queryKey: ['companies-back-paginator', page],
    queryFn: ({ signal }) =>
      getCompanies({
        page,
        locale: 'fr',
        order: { name: 'asc' },
        itemsPerPage: 20,
        signal,
        token,
      }),
    cacheTime: 0,
  });

  const deleteCompanyData = async (id) => {
    await deleteCompany(id);
    await refetch();
  };

  const handlePageChange = (event, page) => {
    setPage(page);
    window.scrollTo(0, 0);
  };

  return (
    <Container maxWidth sx={{ py: 5 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Company Name</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Sponsoré</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading && (
            <TableRow>
              <TableCell>
                <CircularProgress color="primary" />
              </TableCell>
            </TableRow>
          )}

          {error && (
            <TableRow>
              <TableCell>{error.message}</TableCell>
            </TableRow>
          )}

          {data?.data?.['hydra:member']?.map((company) => (
            <TableRow key={company.id}>
              <TableCell>{company.name}</TableCell>
              <TableCell>{format(new Date(company.creationDate), 'dd/MM/yyyy')}</TableCell>
              <TableCell>{company.state}</TableCell>
              <TableCell>{company.isSponsored ? 'oui' : 'non'}</TableCell>

              <TableCell>
                <Button
                  variant="outlined"
                  color="info"
                  style={{ marginRight: 10 }}
                  component={Link}
                  to={`${company.id}`}
                >
                  <InfoIcon />
                </Button>
                <Button
                  variant="outlined"
                  style={{ marginRight: 10 }}
                  component={Link}
                  to={`edit/${company.id}`}
                >
                  <EditIcon />
                </Button>
                <Button
                  color="error"
                  variant="outlined"
                  onClick={() => {
                    setCompanyIdToDelete(company.id);
                    setDeleteModalOpen(true);
                  }}
                >
                  <DeleteIcon />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {data?.data?.['hydra:view']['hydra:last']?.split('page=')[1] > 1 && (
        <Pagination
          sx={{ display: 'flex', justifyContent: 'center', my: 5 }}
          count={parseInt(data?.data?.['hydra:view']['hydra:last']?.split('page=')[1])}
          page={page}
          onChange={handlePageChange}
        />
      )}

      <Modal
        open={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
          setCompanyIdToDelete();
        }}
        maxWidth="lg"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: 500,
            width: '100%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h3">Suppression de la compagnie</Typography>
          <Typography variant="body2" mt={2} mb={4}>
            La suppression est permanente. Etes-vous sûr de vouloir supprimer cette compagnie ?
          </Typography>

          <Stack flexDirection="row" justifyContent="end">
            <Button
              onClick={() => {
                setDeleteModalOpen(false);
                setCompanyIdToDelete();
              }}
              variant="contained"
              color="secondary"
              sx={{ mr: 2 }}
            >
              Non
            </Button>
            <Button
              onClick={() => {
                deleteCompanyData(companyIdToDelete);
                setDeleteModalOpen(false);
                setCompanyIdToDelete();
              }}
              variant="contained"
              color="error"
            >
              Oui
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Container>
  );
};

export default CompanyList;
