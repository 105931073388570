import React from 'react';
import {
  Container,
  Grid,
  Button,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
} from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { format } from 'date-fns';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { deleteCompany, getCompany } from 'services/Company';
import LoadingComponent from 'Components/LoadingComponent';
import { getStorage } from 'utils/storage';
import { getJobs, deleteJob } from 'services/Job';

const Company = () => {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const token = getStorage('token');

  const { isLoading, data } = useQuery({
    queryKey: ['company-back'],
    queryFn: () => getCompany(companyId),
    cacheTime: 0,
  });

  const {
    isLoadingJobs,
    data: jobsData,
    refetch: refetchJobs,
  } = useQuery({
    queryKey: ['job-company-back'],
    queryFn: () => getJobs({ companyId: parseInt(companyId), pagination: false, token }),
    cacheTime: 0,
  });

  const deleteCompanyData = async (id) => {
    await deleteCompany(id);
    navigate('/admin/companies');
  };

  const deleteJobData = async (id) => {
    await deleteJob(id);
    await refetchJobs();
  };

  if (isLoading) {
    return <LoadingComponent />;
  }

  const company = data?.data;
  const jobs = jobsData?.data?.['hydra:member'];

  return (
    <Container maxWidth sx={{ py: 5 }}>
      {company.logo && (
        <img
          src={`${process.env.REACT_APP_API_URL}${company.logo.contentUrl}`}
          alt=""
          width="60px"
        />
      )}

      <Grid container>
        <Grid item xs={12} md={8}>
          <Typography variant="h2">{company.name}</Typography>
          <Typography>
            Date de création: {format(new Date(company.creationDate), 'dd/MM/yyyy')}
          </Typography>
          <Typography>Statut: {company.state}</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Button
            variant="contained"
            component={Link}
            to={`/admin/companies/edit/${company.id}`}
            sx={{ display: 'block' }}
          >
            Modifier
          </Button>
          <Button
            color="error"
            variant="contained"
            onClick={() => deleteCompanyData(company.id)}
            sx={{ width: '100%', mt: 3 }}
          >
            Supprimer
          </Button>
        </Grid>
      </Grid>

      <Grid container sx={{ mt: 4, mb: 2 }}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            component={Link}
            to={`/admin/jobs/add`}
            state={{ companyId: company.id }}
          >
            Ajouter un job
          </Button>
        </Grid>
      </Grid>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Offre d&apos;emplois</TableCell>
            <TableCell>Visible</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {isLoadingJobs && (
            <TableRow>
              <TableCell>
                <CircularProgress color="primary" />
              </TableCell>
            </TableRow>
          )}

          {jobs?.length > 0 ? (
            jobs.map((job) => (
              <TableRow key={job.id}>
                <TableCell>{job.title}</TableCell>
                <TableCell>{job.isVisible ? 'oui' : 'non'}</TableCell>
                <TableCell>
                  {!job.reference ? (
                    <>
                      <Button
                        variant="outlined"
                        component={Link}
                        to={`/admin/jobs/edit/${job.id}`}
                        sx={{ mr: '10px' }}
                      >
                        <EditIcon />
                      </Button>
                      <Button variant="outlined" onClick={() => deleteJobData(job.id)}>
                        <DeleteIcon />
                      </Button>
                    </>
                  ) : (
                    <Typography variant="body2">Géré par Skeeled</Typography>
                  )}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell>
                <Typography>Aucune offre d&apos;emploi actuellement</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Container>
  );
};

export default Company;
