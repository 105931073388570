import axios from 'axios';
import { cleanUrlSearchParams } from '../utils/dataConvertor';

/**
 * @param {string=} title
 * @param {string=} companyName
 * @param {number=} companyId
 * @param {number=} page
 * @param {number=} itemsPerPage
 * @param {boolean=} pagination
 * @param {string=} locale
 * @param {boolean=} isVisible
 * @param {object=} order
 * @param {[string]=} search
 * @param {[string]=} contractTypes
 * @param {[string]=} types
 * @param {boolean=} front
 * @param {AbortSignal=} signal
 * @param {string=} token
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getJobs = ({
  title,
  companyName,
  companyId,
  page,
  itemsPerPage,
  pagination,
  isVisible,
  locale,
  order,
  search,
  contractTypes,
  types,
  front,
  signal,
  token,
}) => {
  let params = new URLSearchParams({
    title,
    companyName,
    page,
    itemsPerPage,
    pagination,
    isVisible,
    locale,
    search,
    front,
    'company.id': companyId,
  });

  if (order) {
    for (const paramsKey in order) {
      params.append(`order[${paramsKey}]`, order[paramsKey]);
    }
  }

  if (contractTypes) {
    contractTypes.forEach((contractType) => {
      params.append('contractType.code[]', contractType);
    });
  }

  if (types) {
    types.forEach((type) => {
      params.append('type.code[]', type);
    });
  }

  params = cleanUrlSearchParams(params).toString();

  return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/jobs?${params}`, {
    signal,
    ...(token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {}),
  });
};

export const getJob = (jobId) => axios.get(`/api/jobs/${jobId}`);

export const deleteJob = async (id) => {
  return await axios.delete(`/api/jobs/${id}`);
};

export const editJob = async (id, job) => {
  return await axios.put(`/api/jobs/${id}`, job);
};

export const createJob = async (job) => {
  return await axios.post(`/api/jobs`, job);
};
