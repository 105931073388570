import React from 'react';
import { Typography } from '@mui/material';

import facebook from 'assets/facebook-24.png';
import linkedin from 'assets/linkedin-24.png';
import instagram from 'assets/instagram(1).svg';
import AEOMBox from 'Components/ui/AEOMBox/AEOMBox';
import AEOMButton from 'Components/ui/AEOMButton';

const FollowUs = () => {
  return (
    <AEOMBox display="flex" flexDirection="column">
      <Typography
        variant="subtitle1"
        mb={0}
        sx={{
          textAlign: 'center',
          fontSize: '15px',
          fontWeight: 'bold',
          color: 'white.main',
          marginLeft: '-4px',
        }}
      >
        Suivez-nous {/*TK: followUs*/}
      </Typography>

      <AEOMBox display="flex" flexDirection="row">
        <AEOMButton
          sx={{ mx: -2 }}
          variant="text"
          component="a"
          href="https://www.linkedin.com/company/89667790/"
          target="_blank"
        >
          <img src={linkedin} style={{ width: '100%' }} />
        </AEOMButton>

        <AEOMButton
          sx={{ mx: -2, mt: 0.5 }}
          variant="text"
          component="a"
          href="https://www.instagram.com/alleyesonme.jobs/"
          target="_blank"
        >
          <img src={instagram} style={{ width: '100%' }} />
        </AEOMButton>

        <AEOMButton
          sx={{ mx: -2 }}
          variant="text"
          component="a"
          href="https://www.facebook.com/profile.php?id=100089639121482"
          target="_blank"
        >
          <img src={facebook} style={{ width: '100%' }} />
        </AEOMButton>
      </AEOMBox>
    </AEOMBox>
  );
};

export default FollowUs;
