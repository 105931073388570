import { getStorage } from '../utils/storage';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ role, children }) => {
  const user = getStorage('user');

  if (
    (role === 'ANONYMOUS' && user) ||
    (role === 'COMPANY' && !user) ||
    (role === 'ADMIN' && !user.roles.some((uRole) => uRole === 'ROLE_ADMIN'))
  ) {
    return <Navigate to="/" />;
  }

  return children;
};

ProtectedRoute.propTypes = {
  role: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default ProtectedRoute;
