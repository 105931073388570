/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { Card, Grid, Typography, Button, TextField } from '@mui/material';
import { useDrop, useDrag } from 'react-dnd';
import DeleteIcon from '@mui/icons-material/Delete';

import AEOMAutocomplete from 'Components/ui/AEOMAutocomplete';
import { deleteTrend } from 'services/Trend';
import { getStorage } from 'utils/storage';

const Trend = ({ index, trend, setTrends, moveCard, companies }) => {
  const token = getStorage('token');
  const [trendCompanies, setTrendCompanies] = useState([]);
  const ref = useRef(null);

  const deleteTrendData = async () => {
    deleteTrend({ id: trend.id, token }).then(() => {
      setTrends((trends) => {
        return trends.filter((tmpTrend) => tmpTrend.id !== trend.id);
      });
    });
  };

  const [{ handlerId }, drop] = useDrop({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: () => {
      return { id: trend.id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));

  useEffect(() => {
    const localTrendCompanies = [];
    trend.companies.forEach((tCompany) => {
      const findCompany = companies.find((company) => company.id === tCompany.id);

      if (findCompany) {
        localTrendCompanies.push(findCompany);
      }
    });

    setTrendCompanies(localTrendCompanies);
  }, [trend.companies]);

  return (
    <Card
      ref={ref}
      sx={{ opacity, border: '1px dashed gray', p: 2, mb: 1, '&:hover': { cursor: 'move' } }}
      data-handler-id={handlerId}
    >
      <Grid container>
        <Grid item xs={12} md>
          <Typography>
            #{index + 1}: {trend.name}
          </Typography>
        </Grid>
        <Grid item xs={12} md="auto">
          <Button variant="outlined" onClick={() => deleteTrendData()}>
            <DeleteIcon />
          </Button>
        </Grid>
      </Grid>

      <AEOMAutocomplete
        sx={{ mt: 2 }}
        multiple
        disableCloseOnSelect
        disableClearable
        options={companies}
        title="name"
        renderInput={(params) => (
          <TextField {...params} variant="standard" label="Entreprises" placeholder="Entreprise" />
        )}
        value={trendCompanies}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        loading={companies.length === 0}
        onChange={(event, values) => {
          if (values.length <= 5) {
            setTrendCompanies(values);

            setTrends((trends) => {
              const trendIndex = trends.findIndex((tmpTrend) => tmpTrend.id === trend.id);
              trends[trendIndex] = { ...trends[trendIndex], companies: values };
              return trends;
            });
          }
        }}
      />
    </Card>
  );
};

export default Trend;
