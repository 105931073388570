import React, { lazy } from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';

import NotFound from 'Pages/NotFound';
import Login from 'Pages/Login';
import RequestResetPassword from 'Pages/resetPassword/request';
import ResetPassword from 'Pages/resetPassword/reset';
import BecomeClient from 'Pages/BecomeClient';
import Navbar from 'Components/Navbar';
import Footer from 'Components/Footer';
import ScrollToTop from 'Components/ScrollToTop';
import ProtectedRoute from 'Components/ProtectedRoute';
import Terms from 'Pages/footerdocx/TermsConditions';
import Privacy from 'Pages/footerdocx/privacyPolicy';
import Charte from 'Pages/footerdocx/recruitmentChart';
import Cookies from 'Pages/footerdocx/cookiePolicy';
import CompanyList from 'Pages/Admin/Companies/CompanyList';
import EditCompany from 'Pages/Admin/Companies/EditCompany';
import JobList from 'Pages/Admin/Jobs/JobList';
import CreateJob from 'Pages/Admin/Jobs/CreateJob';
import EditJob from 'Pages/Admin/Jobs/EditJob';
import AdminCompany from 'Pages/Admin/Companies/Company';
import AdminTrends from 'Pages/Admin/Trends/Trends';

const Home = lazy(() => import('Pages/home'));
const Jobs = lazy(() => import('Pages/jobs/list_of_jobs'));
const Offer = lazy(() => import('Pages/jobs/offer'));
const Media = lazy(() => import('Pages/media/blog'));
const Articles = lazy(() => import('Pages/media/articles'));
const Company = lazy(() => import('Pages/company/list_of_company/ListOfcompany'));
const CompanyProfile = lazy(() => import('Pages/company'));

export default function RouteNav() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Navbar />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/cookies" element={<Cookies />} />
          <Route path="/charte" element={<Charte />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/jobs/:jobId" element={<Offer />} />
          <Route path="/media" element={<Media />} />
          <Route path="/articles/:articleId" element={<Articles />} />
          <Route path="/company" element={<Company />} />
          <Route path="/company/:companyId" element={<CompanyProfile />} />

          <Route
            path="/login"
            element={
              <ProtectedRoute role="ANONYMOUS">
                <Login />
              </ProtectedRoute>
            }
          />
          <Route
            path="/resetPassword"
            element={
              <ProtectedRoute role="ANONYMOUS">
                <RequestResetPassword />
              </ProtectedRoute>
            }
          />
          <Route
            path="/resetPassword/reset"
            element={
              <ProtectedRoute role="ANONYMOUS">
                <ResetPassword />
              </ProtectedRoute>
            }
          />
          <Route
            path="/becomeClient"
            element={
              <ProtectedRoute role="ANONYMOUS">
                <BecomeClient />
              </ProtectedRoute>
            }
          />

          <Route
            path="/admin/companies"
            element={
              <ProtectedRoute role="COMPANY">
                <CompanyList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/companies/:companyId"
            element={
              <ProtectedRoute role="COMPANY">
                <AdminCompany />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/companies/edit/:companyId"
            element={
              <ProtectedRoute role="COMPANY">
                <EditCompany />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/jobs"
            element={
              <ProtectedRoute role="COMPANY">
                <JobList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/jobs/add"
            element={
              <ProtectedRoute role="COMPANY">
                <CreateJob />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/jobs/edit/:jobId"
            element={
              <ProtectedRoute role="COMPANY">
                <EditJob />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/trends"
            element={
              <ProtectedRoute role="ADMIN">
                <AdminTrends />
              </ProtectedRoute>
            }
          />

          <Route path={'*'} element={<NotFound />} />
        </Routes>
      </main>
      <Footer />
    </BrowserRouter>
  );
}
