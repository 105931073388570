/* eslint-disable no-undef */
import { Suspense, useEffect } from 'react';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { GlobalStyles, ThemeProvider } from '@mui/material';
import RouteNav from 'routes';
import axios from 'axios';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyD0cQ8tqvfBhtEN4Fwa9NpvjpjOQm-918Q',
  authDomain: 'aeom-frontend.firebaseapp.com',
  projectId: 'aeom-frontend',
  storageBucket: 'aeom-frontend.appspot.com',
  messagingSenderId: '516696501326',
  appId: '1:516696501326:web:7499da5baa6b9eb621271f',
  measurementId: 'G-C826XMJ9DB',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

import LoadingComponent from 'Components/LoadingComponent';
import theme from 'assets/theme';
import { setupApi } from 'utils/api';
import { getStorage, removeStorage } from './utils/storage';
import { setTokens } from 'utils/user';

setupApi();
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // console.log('error error.response.data.message', error.response.data.message);
    if (
      error?.response?.status === 401 &&
      error.response.data.message !== 'Identifiants invalides.'
    ) {
      removeStorage('user');
      removeStorage('refreshToken');
      removeStorage('token');
      window.location.href = `/login`;
    }
    return error;
  }
);

// logEvent(analytics, 'App.jsx', {});

function App() {
  useEffect(() => {
    const token = getStorage('token');
    const refreshToken = getStorage('refreshToken');
    if (token && refreshToken) setTokens(token, refreshToken);
  }, []);

  return (
    <Suspense fallback={<LoadingComponent />}>
      <GlobalStyles
        styles={{
          body: {
            minHeight: '100vh',
          },
          '#root': {
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
          },
          main: {
            flexGrow: 1,
          },
        }}
      />
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <RouteNav />
          {process.env.NODE_ENV === 'development' ? (
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          ) : null}
        </QueryClientProvider>
      </ThemeProvider>
    </Suspense>
  );
}

export default App;
