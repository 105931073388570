import React, { useEffect, useState } from 'react';
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Button,
  Container,
  CircularProgress,
  Pagination,
  Typography,
  Grid,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useQuery } from 'react-query';

import { getStorage } from 'utils/storage';
import { getJobs, deleteJob } from 'services/Job';

const JobList = () => {
  const token = getStorage('token');
  const user = getStorage('user');

  const [page, setPage] = useState(1);
  const [companyId, setCompanyId] = useState(null);

  const { error, isLoading, data, refetch } = useQuery({
    queryKey: ['jobs-paginator', page],
    queryFn: ({ signal }) =>
      getJobs({
        page,
        locale: 'fr',
        itemsPerPage: 20,
        signal,
        token,
      }),
    cacheTime: 0,
  });

  const deleteJobData = async (id) => {
    await deleteJob(id);
    await refetch();
  };

  const handlePageChange = (event, page) => {
    setPage(page);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (user?.companies?.length > 0) {
      const splittedCompanies = user.companies[0]?.split('/');
      setCompanyId(splittedCompanies[3]);
    }
  }, [user]);

  return (
    <Container maxWidth sx={{ py: 5 }}>
      {!user?.roles?.some((uRole) => uRole === 'ROLE_ADMIN') && companyId && (
        <Grid container sx={{ mt: 4, mb: 2 }}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              component={Link}
              to={`/admin/jobs/add`}
              state={{ companyId }}
            >
              Ajouter un job
            </Button>
          </Grid>
        </Grid>
      )}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Job Name</TableCell>
            <TableCell>Company</TableCell>
            <TableCell>Created</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading && (
            <TableRow>
              <TableCell>
                <CircularProgress color="primary" />
              </TableCell>
            </TableRow>
          )}

          {error && (
            <TableRow>
              <TableCell>{error.message}</TableCell>
            </TableRow>
          )}

          {data?.data?.['hydra:member'].map((job) => (
            <TableRow key={job.id}>
              <TableCell>{job.title}</TableCell>
              <TableCell>{job.company.name}</TableCell>
              <TableCell>{format(new Date(job.creationDate), 'dd/MM/yyyy')}</TableCell>

              <TableCell>
                {!job.reference ? (
                  <>
                    <Button
                      variant="outlined"
                      style={{ marginRight: 10 }}
                      component={Link}
                      to={`edit/${job.id}`}
                    >
                      <EditIcon />
                    </Button>
                    <Button variant="outlined" onClick={() => deleteJobData(job.id)}>
                      <DeleteIcon />
                    </Button>
                  </>
                ) : (
                  <Typography variant="body2">Géré par Skeeled</Typography>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {data?.data?.['hydra:view']['hydra:last']?.split('page=')[1] > 1 && (
        <Pagination
          sx={{ display: 'flex', justifyContent: 'center', my: 5 }}
          count={parseInt(data?.data?.['hydra:view']['hydra:last']?.split('page=')[1])}
          page={page}
          onChange={handlePageChange}
        />
      )}
    </Container>
  );
};

export default JobList;
