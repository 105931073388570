import axios from 'axios';

import { cleanUrlSearchParams } from 'utils/dataConvertor';

/**
 * @param {number=} page
 * @param {boolean=} pagination
 * @param {string=} locale
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
const getJobTypes = async ({ page, pagination, locale }) => {
  let params = new URLSearchParams({
    page,
    pagination,
    locale,
  });

  params = cleanUrlSearchParams(params).toString();

  return await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/job_types?${params}`);
};

export { getJobTypes };
