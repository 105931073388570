export const createTranslation = (locale, title, mission, lookingFor, description) => {
  return {
    title,
    description,
    locale,
    lookingFor,
    mission,
    weAreLookingFor: lookingFor,
  };
};

export const createTranslationForCompany = (locale, weAreLookingFor, description, citation) => {
  return {
    description,
    locale,
    weAreLookingFor,
    citation,
  };
};
