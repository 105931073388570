import React, { useState } from 'react';
import { Container, Grid, Button, Typography, TextField } from '@mui/material';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useQuery } from 'react-query';
import update from 'immutability-helper';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { getTrends, postTrend, updateTrend } from 'services/Trend';
import LoadingComponent from 'Components/LoadingComponent';
import Trend from 'Pages/Admin/Trends/Trend';
import { getCompanies } from 'services/Company';
import { getStorage } from 'utils/storage';
import { createSlug } from 'utils/dataConvertor';

const schema = yup.object({
  name: yup.string().required(),
});

const Trends = () => {
  const token = getStorage('token');
  const [trends, setTrends] = useState([]);
  const [isSaveTrends, setIsSaveTrends] = useState(false);

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });

  const { isLoading, refetch } = useQuery({
    queryKey: ['trends-back'],
    queryFn: () =>
      getTrends({
        locale: 'fr',
        pagination: false,
      }).then((response) => setTrends(response.data['hydra:member'])),
    cacheTime: 0,
  });

  const { isLoadingCompanies, data } = useQuery({
    queryKey: ['companies-back-trend'],
    queryFn: () =>
      getCompanies({
        locale: 'fr',
        pagination: false,
      }),
    cacheTime: 0,
  });

  const addTrends = (data, e) => {
    postTrend({
      data: {
        companies: [],
        orderNumber: 0,
        code: createSlug(data.name),
        translations: {
          fr: {
            name: data.name,
            locale: 'fr',
          },
        },
      },
      token,
    }).then(() => {
      e.target.reset();
      refetch();
    });
  };

  const saveTrends = () => {
    setIsSaveTrends(true);
    Promise.all(
      trends.map((trend, index) => {
        updateTrend({
          id: trend.id,
          data: {
            ...trend,
            orderNumber: index,
            companies: trend.companies.map((company) => company['@id']),
          },
          token,
        });
      })
    ).then(() => {
      setIsSaveTrends(false);
      refetch();
    });
  };

  if (isLoading || isSaveTrends) {
    return <LoadingComponent />;
  }

  const moveCard = (dragIndex, hoverIndex) => {
    setTrends((prevTrends) =>
      update(prevTrends, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevTrends[dragIndex]],
        ],
      })
    );
  };

  return (
    <Container maxWidth="xl" sx={{ py: 5 }}>
      <Typography variant="h2" sx={{ mb: 3 }}>
        Trends
      </Typography>

      <Grid container sx={{ mb: 3 }}>
        <Grid item xs={12} md>
          <form onSubmit={handleSubmit(addTrends)}>
            <Typography variant="h4">Ajouter un trend</Typography>
            <Grid sx={{ mb: 2 }}>
              <Controller
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    fullWidth
                    {...field}
                    id="name"
                    defaultValue=""
                    helperText={error ? error.message : null}
                    error={!!error}
                    sx={{ maxWidth: '500px' }}
                  />
                )}
                name="name"
              />
            </Grid>
            <Button variant="contained" type="submit">
              Ajouter
            </Button>
          </form>
        </Grid>
        <Grid item xs={12} md="auto" onClick={() => saveTrends()}>
          <Button variant="contained">Enregistrer</Button>
        </Grid>
      </Grid>

      {!isLoadingCompanies && data?.data?.['hydra:member'] && (
        <DndProvider backend={HTML5Backend}>
          {trends.map((trend, index) => (
            <Trend
              key={trend['@id']}
              index={index}
              trend={trend}
              setTrends={setTrends}
              moveCard={moveCard}
              companies={data.data['hydra:member']}
            />
          ))}
        </DndProvider>
      )}

      {/*  TODO: ajouter le formulaire*/}
    </Container>
  );
};

export default Trends;
