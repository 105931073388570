// All Eyes On Me base styles
import colors from '../base/colors';

const { primary, white } = colors;

export default {
  styleOverrides: {
    filledSuccess: {
      backgroundColor: primary.main,
      color: white.main,
    },
  },
};
