import React from 'react';
import { Container, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

function cookiePolicy() {
  return (
    <>
      <Container disableGutters maxWidth="md" component="main" sx={{ pt: 8, pb: 6 }}>
        <Typography component="h2" color="dark" gutterBottom>
          Politique de gestion des cookies de All Eyes On Me
        </Typography>
        <Typography color="dark" component="h3" mt={3} mb={0}>
          Version en date du 14.06.2023
        </Typography>
        <Typography fontWeight="light" color="dark" fontSize="16px" component="p" mt={2} mb={2}>
          All Eyes On Me via son Site rend les entreprises plus attractives en faisant rayonner leur
          marque employeur et en permettant d’offrir une expérience de recrutement fluide aux
          candidats et aux recruteurs. All Eyes On Me met également à disposition de ses
          utilisateurs un média sur le travail reprenant des articles, interviews et des ebooks.
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
          La Politique de gestion des Cookies a pour objectif de définir les règles applicables au
          dépôt et l’utilisation de cookies via la navigation sur notre site alleyesonme.jobs, ou
          ses sous-domaines (ci-après notre « Site AEOM ») et l’utilisation des Services mis en
          place par Coruscant (ci-après, « Nous », « All Eyes On Me » ou « AEOM »).
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
          La Politique de gestion des Cookies fait partie intégrante de notre Politique de
          Confidentialité et est soumise aux mêmes définitions, règles d’acceptation et de mise à
          jour. Vous pouvez consulter la dite Politique de Confidentialité via le lien suivant :
          <br />
          <Link
            style={{
              color: 'blue',
            }}
            to="https://www.alleyesonme/privacy-policy"
          >
            https://www.alleyesonme/privacy-policy.{' '}
          </Link>
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
          En poursuivant votre navigation sur notre Site ou votre utilisation de nos Services, vous
          acceptez l’utilisation de cookies et de technologies similaires dans les conditions
          décrites au sein de la présente politique.
        </Typography>
        <Typography color="dark" component="h3" mt={3} mb={2}>
          1. Qu’est-ce qu’un cookie ?
        </Typography>
        <Typography color="dark" component="h3" mt={0} mb={0}>
          1.1 Cookies internes et cookies tiers :
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
          Un cookie tiers est placé sur votre terminal par un serveur d’un domaine distinct de celui
          du Site. Ces tiers installent des cookies lorsque vous êtes connectés sur leurs pages ou
          que vous consultez notre Site. Ces cookies permettent de suivre un comportement sur un
          réseau de sites, en analysant l’historique de visites à des fins de ciblage.
          <br />
          All Eyes On Me n’a pas accès à ces cookies à la différence des cookies internes qui sont
          directement géré par notre Site.
        </Typography>
        <Typography color="dark" component="h3" mt={0} mb={0}>
          1.2 Cookies permanents et cookies de session :
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
          Les cookies permanents sont stockés sur votre terminal jusqu’à sa date d’expiration ou
          jusqu’à ce que vous le supprimiez. <br />
          Les cookies de session sont supprimés automatiquement lorsque vous quittez votre
          navigateur.
        </Typography>
        <Typography color="dark" component="h3" mt={3} mb={2}>
          2. Comment gérer les cookies ?
        </Typography>
        <Typography color="dark" component="h3" mt={0} mb={0}>
          2.1 Accepter, refuser ou paramétrer les cookies lors de votre première visite
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
          Le dépôt des cookies sur votre terminal suppose le recueil de votre consentement, ou de
          notre intérêt légitime lorsqu’il s’agit de cookies strictement nécessaires. Ainsi, dès
          votre arrivée sur notre Site, un bandeau d’information vous indique que nous utilisons
          cette technologie et qu’en poursuivant votre navigation, vous acceptez le dépôt de cookies
          sur votre terminal.
          <br /> Vous pouvez néanmoins les paramétrer à tout moment en cliquant sur le lien suivant
          :
        </Typography>
        <Typography
          sx={{ ml: { xs: 4, md: 4 }, mt: 2 }}
          fontWeight="light"
          fontSize="16px"
          color="dark"
          component="p"
          mt={2}
          mb={2}
        >
          <li>Pour le site alleyesonme.jobs : Paramétrer les cookies</li>
          <li>Pour le site solutions.alleyesonme.jobs : Paramétrer les cookies</li>
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
          La suppression de cookies fonctionnels, qui sont nécessaires à la fourniture de contenus
          par All Eyes On Me, peut vous empêcher d’accéder à certains contenus du Site. <br />
          All Eyes On Me décline toute responsabilité relativement aux conséquences liées au
          fonctionnement dégradé de son Site résultant de l’impossibilité d’enregistrer ou de
          consulter les cookies nécessaires au fonctionnement du Site, et dont vous auriez refusé le
          dépôt ou requis la suppression.
        </Typography>
        <Typography color="dark" component="h3" mt={0} mb={0}>
          2.2 Accepter, refuser ou paramétrer les cookies via votre navigateur
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
          Vous avez également la possibilité de gérer le dépôt de cookies à l’aide du paramétrage de
          votre navigateur, par les moyens décrits ci-dessous. Choix offerts par votre logiciel de
          navigation : Vous pouvez configurer votre logiciel de navigation de manière que des
          cookies soient enregistrés sur votre terminal ou, au contraire, qu’ils soient rejetés,
          soit systématiquement, soit selon leur émetteur. Vous pouvez également configurer votre
          logiciel de navigation de manière que l’acceptation ou le refus des cookies vous soit
          proposé, avant qu’un cookie soit susceptible d’être enregistré sur votre terminal.
          <br />
          La gestion des cookies diffère selon la configuration de chaque navigateur. Pour plus de
          détails, cliquez sur les liens ci-dessous.
        </Typography>
        <Typography
          sx={{ ml: { xs: 4, md: 4 }, mt: 2 }}
          fontWeight="light"
          fontSize="16px"
          color="dark"
          component="p"
          mt={2}
          mb={2}
        >
          <li>
            Pour Internet Explorer :
            <Link
              style={{
                color: 'blue',
              }}
              to="https://support.microsoft.com/en-gb/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
            >
              https://support.microsoft.com/en-gb/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d.{' '}
            </Link>
          </li>
          <li>
            Pour Safari :
            <Link
              style={{
                color: 'blue',
              }}
              to="https://support.apple.com/en-gb/guide/safari/sfri11471/mac"
            >
              https://support.apple.com/en-gb/guide/safari/sfri11471/mac.{' '}
            </Link>
          </li>
          <li>
            Pour Google Chrome :
            <Link
              style={{
                color: 'blue',
              }}
              to="https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en"
            >
              https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en.{' '}
            </Link>
          </li>
          <li>
            Pour Firefox :
            <Link
              style={{
                color: 'blue',
              }}
              to="https://support.mozilla.org/fr/kb/protection-renforcee-contre-pistage-firefox-ordinateur"
            >
              https://support.mozilla.org/fr/kb/protection-renforcee-contre-pistage-firefox-ordinateur.{' '}
            </Link>
          </li>
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
          Ce paramétrage sera susceptible de modifier votre navigation sur Internet et vos
          conditions d’accès à certains services nécessitant l’utilisation de cookies. Ce
          paramétrage n’empêchera pas l’affichage de publicités sur les sites Internet que vous
          visitez mais il bloquera les technologies permettant d’adapter des publicités à vos
          centres d’intérêts. Pour plus d’information sur les cookies et leur utilisation, vous
          pouvez consulter le site de la Commission Nationale de l’Informatique et des Libertés
          (CNIL) à l’adresse suivante :
          <Link
            style={{
              color: 'blue',
            }}
            to="https://www.cnil.fr/fr/site-web-cookies-et-autres-traceurs."
          >
            https://www.cnil.fr/fr/site-web-cookies-et-autres-traceurs.{' '}
          </Link>
        </Typography>
        <Typography color="dark" component="h3" mt={0} mb={0}>
          2.3 Accepter, refuser ou paramétrer les cookies via votre smartphone
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
          Vous pouvez décider de modifier le paramétrage de confidentialité de votre smartphone.
        </Typography>
        <Typography
          sx={{ ml: { xs: 4, md: 4 }, mt: 2 }}
          fontWeight="light"
          fontSize="16px"
          color="dark"
          component="p"
          mt={2}
          mb={2}
        >
          <li>
            Système Apple :
            <Link
              style={{
                color: 'blue',
              }}
              to="https://support.apple.com/fr-fr/HT201265"
            >
              https://support.apple.com/fr-fr/HT201265.{' '}
            </Link>
          </li>
          <li>
            Système Android :
            <Link
              style={{
                color: 'blue',
              }}
              to="https://support.google.com/chrome/answer/95647"
            >
              https://support.google.com/chrome/answer/95647.{' '}
            </Link>
          </li>
        </Typography>
        <Typography color="dark" component="h3" mt={3} mb={2}>
          3. Quel type de cookies utilisons-nous ?
        </Typography>
        <Typography color="dark" component="h3" mt={0} mb={0}>
          3.1 Cookies Strictement Nécessaires
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
          Ces cookies assurent les fonctionnalités essentielles du Site et assurent le bon
          fonctionnement du Site. Ils ne peuvent pas être désactivés de nos systèmes. Vous pouvez
          cependant configurer votre navigateur pour bloquer ou être alerté de l’utilisation de ces
          cookies. Le cas échéant, certaines parties du site ne pourront pas fonctionner.
          <br />
          L’utilisation de ces cookies par notre Site est fondée sur notre intérêt légitime afin
          d’assurer la sécurité, le fonctionnement technique et l’accessibilité du Site et de nos
          différents services.
          <br />
          Les cookies strictement nécessaires utilisés sur le site alleyesonme.jobs sont :
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          <table border="1px solid" style={{ borderCollapse: 'collapse' }}>
            <tr>
              <th>Domaine</th>
              <th>Nom du cookie</th>
              <th>Durée de conservation</th>
            </tr>
            <tr>
              <td>All Eyes On Me</td>
              <td>current_country_code</td>
              <td>1 jour</td>
            </tr>
            <tr>
              <td></td>
              <td>csrf-token</td>
              <td>Fin de la session</td>
            </tr>
            <tr>
              <td></td>
              <td>AEOM_api_session_key</td>
              <td>Fin de la session</td>
            </tr>
            <tr>
              <td></td>
              <td>AEOM-user-language</td>
              <td>1 jour</td>
            </tr>
            <tr>
              <td> Amazon Web Services </td>
              <td>AWSALBCORS</td>
              <td>7 jours</td>
            </tr>
            <tr>
              <td></td>
              <td>AWSALB</td>
              <td>7 jours</td>
            </tr>
            <tr>
              <td> Axeptio</td>
              <td>connect.sid</td>
              <td>Fin de la session</td>
            </tr>
            <tr>
              <td></td>
              <td>axeptio_authorized_vendors</td>
              <td>1 an</td>
            </tr>
            <tr>
              <td></td>
              <td>axeptio_cookies</td>
              <td>1 an</td>
            </tr>
            <tr>
              <td></td>
              <td>axeptio_all_vendors</td>
              <td>1 an</td>
            </tr>
            <tr>
              <td> Snowplow</td>
              <td>_sp_id</td>
              <td>2 ans</td>
            </tr>
            <tr>
              <td></td>
              <td>_sp_ses</td>
              <td>30 minutes</td>
            </tr>
            <tr>
              <td></td>
              <td>sp</td>
              <td>1 an</td>
            </tr>
          </table>
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
          Les cookies strictement nécessaires utilisés sur le site solutions.alleyesonme.jobs sont :
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          <table border="1px solid" style={{ borderCollapse: 'collapse' }}>
            <tr>
              <th>Domaine</th>
              <th>Nom du cookie</th>
              <th>Durée de conservation</th>
            </tr>
            <tr>
              <td>Hubspot</td>
              <td>__hs_opt_out</td>
              <td>6 mois</td>
            </tr>
            <tr>
              <td></td>
              <td>__hs_do_not_track</td>
              <td>6 mois</td>
            </tr>
            <tr>
              <td></td>
              <td>__hs_initial_opt_in</td>
              <td>7 jours</td>
            </tr>
            <tr>
              <td></td>
              <td>__hs_cookie_cat_pref</td>
              <td>6 mois</td>
            </tr>
            <tr>
              <td></td>
              <td>hs_ab_test</td>
              <td>Fin de la session</td>
            </tr>
            <tr>
              <td></td>
              <td>_key</td>
              <td>14 jours</td>
            </tr>
            <tr>
              <td></td>
              <td>hs-messages-is-open</td>
              <td>30 minutes</td>
            </tr>
            <tr>
              <td></td>
              <td>hs-messages-hide-welcome-message</td>
              <td>1 jour</td>
            </tr>
            <tr>
              <td></td>
              <td>__hsmem</td>
              <td>7 jours</td>
            </tr>
            <tr>
              <td></td>
              <td>hs-membership-csrf</td>
              <td>Fin de la session</td>
            </tr>
            <tr>
              <td></td>
              <td>hs_langswitcher_choice</td>
              <td>2 ans</td>
            </tr>
            <tr>
              <td></td>
              <td>__cfruid</td>
              <td>Fin de la session</td>
            </tr>
            <tr>
              <td></td>
              <td>__cf_bm</td>
              <td>30 minutes</td>
            </tr>
            <tr>
              <td>Axeptio</td>
              <td>connect.sid</td>
              <td>Fin de la session</td>
            </tr>
            <tr>
              <td></td>
              <td>axeptio_authorized_vendors</td>
              <td>1 an</td>
            </tr>
            <tr>
              <td></td>
              <td>axeptio_cookies</td>
              <td>1 an</td>
            </tr>
            <tr>
              <td></td>
              <td>axeptio_all_vendors</td>
              <td>1 an</td>
            </tr>
          </table>
        </Typography>
        <Typography color="dark" component="h3" mt={0} mb={0}>
          3.2 Cookies de fonctionnalité
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
          Ces cookies nous permettent d’améliorer et de personnaliser les fonctionnalités du Site.
          Ils peuvent être activés par All Eyes On Me, ou par des tiers dont les services sont
          utilisés sur les pages de notre Site.
          <br />
          L’utilisation de ces cookies est fondée sur votre consentement, si vous refusez ces
          cookies, une partie ou la totalité de ces services risque de ne pas fonctionner
          correctement.
          <br />
          Les cookies de fonctionnalité utilisés sur le site alleyesonme.jobs sont :
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          <table border="1px solid" style={{ borderCollapse: 'collapse' }}>
            <tr>
              <th>Domaine</th>
              <th>Nom du cookie</th>
              <th>Durée de conservation</th>
            </tr>
            <tr>
              <td>Beamer</td>
              <td>BEAMER_FIRST_VISIT {'{product ID}'}</td>
              <td>300 jours</td>
            </tr>
            <tr>
              <td></td>
              <td>BEAMER_USER_ID {'{product ID}'}</td>
              <td>300 jours</td>
            </tr>
            <tr>
              <td></td>
              <td>BEAMER_LAST_UPDATE {'{product ID}'}</td>
              <td>300 jours</td>
            </tr>
            <tr>
              <td></td>
              <td>BEAMER_DATE {'{product ID}'}</td>
              <td>300 jours</td>
            </tr>
            <tr>
              <td></td>
              <td>BEAMER_BOOSTED_ANNOUNCEMENT_DATE {'{product ID}'}</td>
              <td>300 jours</td>
            </tr>
            <tr>
              <td></td>
              <td>BEAMER_NPS_LAST_SHOWN {'{product ID}'}</td>
              <td>300 jours</td>
            </tr>
            <tr>
              <td></td>
              <td>BEAMER_LAST_POST_SHOWN {'{product ID}'}</td>
              <td>300 jours</td>
            </tr>
            <tr>
              <td></td>
              <td>BEAMER_SOUND_PLAYED {'{product ID}'}</td>
              <td>300 jours</td>
            </tr>
            <tr>
              <td></td>
              <td>BEAMER_LAST_PUSH_PROMPT_INTERACTION {'{product ID}'}</td>
              <td>300 jours</td>
            </tr>
            <tr>
              <td>Wisepops</td>
              <td>wisepops</td>
              <td>2 ans</td>
            </tr>
            <tr>
              <td></td>
              <td>wisepops_visits</td>
              <td>2 ans</td>
            </tr>
            <tr>
              <td></td>
              <td>wisepops_props</td>
              <td>2 ans</td>
            </tr>
            <tr>
              <td></td>
              <td>wisepops_noshow</td>
              <td>2 ans</td>
            </tr>
            <tr>
              <td></td>
              <td>wisepops_session</td>
              <td>Fin de la session</td>
            </tr>
            <tr>
              <td></td>
              <td>viewedOuibounceModal</td>
              <td>Fin de la session</td>
            </tr>
            <tr>
              <td>Maze</td>
              <td>maze</td>
              <td>2 ans</td>
            </tr>
          </table>
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
          Les cookies de fonctionnalité utilisés sur le site solutions.alleyesonme.jobs sont :
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          <table border="1px solid" style={{ borderCollapse: 'collapse' }}>
            <tr>
              <th>Domaine</th>
              <th>Nom du cookie</th>
              <th>Durée de conservation</th>
            </tr>

            <tr>
              <td>Wisepops</td>
              <td>wisepops</td>
              <td>2 ans</td>
            </tr>
            <tr>
              <td></td>
              <td>wisepops_visits</td>
              <td>2 ans</td>
            </tr>
            <tr>
              <td></td>
              <td>wisepops_props</td>
              <td>2 ans</td>
            </tr>
            <tr>
              <td></td>
              <td>wisepops_noshow</td>
              <td>2 ans</td>
            </tr>
            <tr>
              <td></td>
              <td>wisepops_session</td>
              <td>Fin de la session</td>
            </tr>
            <tr>
              <td></td>
              <td>viewedOuibounceModal</td>
              <td>Fin de la session</td>
            </tr>
            <tr>
              <td>Hubspot</td>
              <td>messagesUtk</td>
              <td>6 mois</td>
            </tr>
            <tr>
              <td></td>
              <td>__hstc</td>
              <td>6 mois</td>
            </tr>
            <tr>
              <td></td>
              <td>hubspotutk</td>
              <td>6 mois</td>
            </tr>
            <tr>
              <td></td>
              <td>__hssc</td>
              <td> 30 minutes</td>
            </tr>
            <tr>
              <td></td>
              <td>__hssrc</td>
              <td>Fin de la session</td>
            </tr>
          </table>
        </Typography>
        <Typography color="dark" component="h3" mt={0} mb={0}>
          3.3 Cookies de performance
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
          Ces cookies nous permettent d’analyser de manière anonyme la fréquentation des pages
          (nombre de visités, activité des visiteurs, fréquences de retour), comment vous naviguez
          sur notre Site et les performances du Site.
          <br />
          L’utilisation de ces cookies est fondée sur votre consentement, si vous refusez ces
          cookies, nous ne pourrons pas savoir quand est-ce que vous avez visité notre Site, ni
          connaître le parcours de votre navigation sur notre Site.
          <br />
          Les cookies de performance utilisés sur le site alleyesonme.jobs sont :
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          <table border="1px solid" style={{ borderCollapse: 'collapse' }}>
            <tr>
              <th>Domaine</th>
              <th>Nom du cookie</th>
              <th>Durée de conservation</th>
            </tr>

            <tr>
              <td>ContentSquare</td>
              <td>_cs_id</td>
              <td>13 mois</td>
            </tr>
            <tr>
              <td></td>
              <td>_cs_s</td>
              <td>30 minutes</td>
            </tr>
            <tr>
              <td></td>
              <td>_cs_vars</td>
              <td>Fin de la session</td>
            </tr>
            <tr>
              <td></td>
              <td>_cs_ex</td>
              <td>13 mois</td>
            </tr>
            <tr>
              <td></td>
              <td>_cs_c</td>
              <td>3 ans</td>
            </tr>
            <tr>
              <td></td>
              <td>_cs_optout</td>
              <td>Fin de la session</td>
            </tr>
            <tr>
              <td>Hotjar</td>
              <td>hjSessionUser {'{site_id}'}</td>
              <td>1 an</td>
            </tr>
            <tr>
              <td></td>
              <td>_hjid</td>
              <td>1 an</td>
            </tr>
            <tr>
              <td></td>
              <td>_hjFirstSeen</td>
              <td>Fin de la session</td>
            </tr>
            <tr>
              <td></td>
              <td>_hjUserAttributesHash</td>
              <td>Fin de la session</td>
            </tr>
            <tr>
              <td></td>
              <td>_hjCachedUserAttributes</td>
              <td>Fin de la session</td>
            </tr>
            <tr>
              <td></td>
              <td>_hjViewportId</td>
              <td>Fin de la session</td>
            </tr>

            <tr>
              <td></td>
              <td>hjSessionUser {'{site_id}'}</td>
              <td>30 minutes</td>
            </tr>
            <tr>
              <td></td>
              <td>_hjSessionTooLarge</td>
              <td>Fin de la session</td>
            </tr>
            <tr>
              <td></td>
              <td>_hjSessionRejected</td>
              <td>Fin de la session</td>
            </tr>
            <tr>
              <td></td>
              <td>_hjSessionResumed</td>
              <td>Fin de la session</td>
            </tr>
            <tr>
              <td></td>
              <td>_hjLocalStorageTest</td>
              <td>Moins de 1 seconde</td>
            </tr>
            <tr>
              <td></td>
              <td>_hjIncludedInPageviewSample</td>
              <td>30 minutes</td>
            </tr>
            <tr>
              <td></td>
              <td>_hjIncludedInSessionSample</td>
              <td>30 minutes</td>
            </tr>
            <tr>
              <td></td>
              <td>_hjAbsoluteSessionInProgress</td>
              <td>30 minutes</td>
            </tr>
            <tr>
              <td></td>
              <td>_hjTLDTest</td>
              <td>Fin de la session</td>
            </tr>
            <tr>
              <td></td>
              <td>_hjRecordingEnabled</td>
              <td>Fin de la session</td>
            </tr>
            <tr>
              <td></td>
              <td>_hjRecordingLastActivity</td>
              <td>Fin de la session</td>
            </tr>
          </table>
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
          Les cookies de performance utilisés sur le site solutions.alleyesonme.jobs sont :
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          <table border="1px solid" style={{ borderCollapse: 'collapse' }}>
            <tr>
              <th>Domaine</th>
              <th>Nom du cookie</th>
              <th>Durée de conservation</th>
            </tr>
            <tr>
              <td>Hotjar</td>
              <td>hjSessionUser {'{site_id}'}</td>
              <td>1 an</td>
            </tr>
            <tr>
              <td></td>
              <td>_hjid</td>
              <td>1 an</td>
            </tr>
            <tr>
              <td></td>
              <td>_hjFirstSeen</td>
              <td>Fin de la session</td>
            </tr>
            <tr>
              <td></td>
              <td>_hjUserAttributesHash</td>
              <td>Fin de la session</td>
            </tr>
            <tr>
              <td></td>
              <td>_hjCachedUserAttributes</td>
              <td>Fin de la session</td>
            </tr>
            <tr>
              <td></td>
              <td>_hjViewportId</td>
              <td>Fin de la session</td>
            </tr>

            <tr>
              <td></td>
              <td>hjSessionUser {'{site_id}'}</td>
              <td>30 minutes</td>
            </tr>
            <tr>
              <td></td>
              <td>_hjSessionTooLarge</td>
              <td>Fin de la session</td>
            </tr>
            <tr>
              <td></td>
              <td>_hjSessionRejected</td>
              <td>Fin de la session</td>
            </tr>
            <tr>
              <td></td>
              <td>_hjSessionResumed</td>
              <td>Fin de la session</td>
            </tr>
            <tr>
              <td></td>
              <td>_hjLocalStorageTest</td>
              <td>Moins de 1 seconde</td>
            </tr>
            <tr>
              <td></td>
              <td>_hjIncludedInPageviewSample</td>
              <td>30 minutes</td>
            </tr>
            <tr>
              <td></td>
              <td>_hjIncludedInSessionSample</td>
              <td>30 minutes</td>
            </tr>
            <tr>
              <td></td>
              <td>_hjAbsoluteSessionInProgress</td>
              <td>30 minutes</td>
            </tr>
            <tr>
              <td></td>
              <td>_hjTLDTest</td>
              <td>Fin de la session</td>
            </tr>
            <tr>
              <td></td>
              <td>_hjRecordingEnabled</td>
              <td>Fin de la session</td>
            </tr>
            <tr>
              <td></td>
              <td>_hjRecordingLastActivity</td>
              <td>Fin de la session</td>
            </tr>
            <tr>
              <td>Wisepops</td>
              <td>wisepops_activity_session</td>
              <td>Fin de la session</td>
            </tr>
            <tr>
              <td>GetQuanty</td>
              <td>cluid</td>
              <td>12 mois</td>
            </tr>
            <tr>
              <td></td>
              <td>Eqy_email, _custom_data_emai</td>
              <td>12 mois</td>
            </tr>
            <tr>
              <td></td>
              <td>eqy_company</td>
              <td>12 mois</td>
            </tr>
            <tr>
              <td></td>
              <td>eqy_sessionid, PHPSESSID</td>
              <td>Fin de la session</td>
            </tr>
            <tr>
              <td></td>
              <td>eqy_siteid</td>
              <td>12 mois</td>
            </tr>
            <tr>
              <td> Google Ads</td>
              <td>__utma</td>
              <td> 90 jours</td>
            </tr>
            <tr>
              <td></td>
              <td>__utmb</td>
              <td> 90 jours</td>
            </tr>
            <tr>
              <td></td>
              <td>__utmc</td>
              <td> 90 jours</td>
            </tr>
            <tr>
              <td></td>
              <td>__utmz</td>
              <td> 90 jours</td>
            </tr>
            <tr>
              <td></td>
              <td>_ga</td>
              <td> 13 mois</td>
            </tr>
            <tr>
              <td></td>
              <td>_gat</td>
              <td> 13 mois</td>
            </tr>
          </table>
        </Typography>
        <Typography color="dark" component="h3" mt={0} mb={0}>
          3.4 Cookies de ciblage
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
          Ces cookies nous permettent de vous proposer des annonces personnalisées en fonction de
          votre navigation sur le site. Ces cookies peuvent être activés sur notre Site par nos
          partenaires et servent ainsi à établir un profil sur vos intérêts et vous proposer des
          offres ciblées sur d’autres sites internet.
          <br />
          L’utilisation de ces cookies est fondée sur votre consentement, si vous refusez ces
          cookies aucune offre personnalisée ne vous sera proposée. Les cookies de ciblage utilisés
          sur le site alleyesonme.jobs sont :
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          <table border="1px solid" style={{ borderCollapse: 'collapse' }}>
            <tr>
              <th>Domaine</th>
              <th>Nom du cookie</th>
              <th>Durée de conservation</th>
            </tr>
            <tr>
              <td>Facebook Pixel</td>
              <td>_fbp</td>
              <td>3 mois</td>
            </tr>
          </table>
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
          Les cookies de ciblage utilisés sur le site solutions.alleyesonme.jobs sont :
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={3} mb={2}>
          <table border="1px solid" style={{ borderCollapse: 'collapse' }}>
            <tr>
              <th>Domaine</th>
              <th>Nom du cookie</th>
              <th>Durée de conservation</th>
            </tr>
            <tr>
              <td>Facebook Pixel</td>
              <td>_fbp</td>
              <td>3 mois</td>
            </tr>
          </table>
        </Typography>
        <Typography color="dark" component="h3" mt={3} mb={2}>
          4. Respect de vos droits
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
          Vous disposez d’un droit d’accès, de rectification, d’opposition, de suppression et de
          limitation pour les informations issues des cookies et autres traceurs. Ces droits peuvent
          être exercés à tout moment en contactant le Délégué à la protection des données (DPO) à
          l’adresse privacy@aleyesonme.jobs. Après nous avoir contacté, si vous estimez que vos
          droits ne sont pas respectés, vous disposez en outre du droit d’introduire une réclamation
          auprès d’une autorité de contrôle.
        </Typography>
        <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
          L’autorité de contrôle luxembourgeoise est la Commission nationale pour la protection des
          données située 15, Boulevard du Jazz L-4370 Belvaux
          <Link
            style={{
              color: 'blue',
            }}
            to="https://cnpd.public.lu/fr.htm"
          >
            (https://cnpd.public.lu/fr.htm){' '}
          </Link>
        </Typography>
      </Container>
    </>
  );
}

export default cookiePolicy;
