import axios from 'axios';

import { cleanUrlSearchParams } from 'utils/dataConvertor';

/**
 * @param {number=} page
 * @param {boolean=} pagination
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
const getCompanySizes = async ({ page, pagination }) => {
  const params = cleanUrlSearchParams(new URLSearchParams({ page, pagination })).toString();

  return await axios.get(`${process.env.REACT_APP_API_URL}/api/company_sizes?${params}`);
};

export { getCompanySizes };
