/**
 * The base colors for the All Eyes On Me.
 * You can add new color using this file.
 * You can customized the colors for the entire All Eyes On Me using this file.
 */
export default {
  background: {
    default: '#f0f2f5',
  },
  text: {
    main: '#03003D',
    focus: '#03003D',
  },
  transparent: {
    main: 'transparent',
  },
  white: {
    main: '#ffffff',
    focus: '#ffffff',
  },
  black: {
    main: '#03003D',
  },
  blue: {
    main: '#000339',
  },
  primary: {
    main: '#ff4f00',
    focus: '#FF9B68',
    light: '#FFCAAF',
    lighter: '#FFDECD',
  },
  secondary: {
    main: '#7b809a',
    focus: '#8f93a9',
  },
  info: {
    main: '#1A73E8',
    focus: '#1662C4',
  },
  success: {
    main: '#05FF8F',
    focus: '#08d177',
  },
  warning: {
    main: '#fb8c00',
    focus: '#fc9d26',
  },
  error: {
    main: '#e53e3e',
    focus: '#f65f53',
  },
  light: {
    main: '#f0f2f5',
    focus: '#f0f2f5',
  },
  dark: {
    main: '#344767',
    focus: '#2c3c58',
  },
  grey: {
    100: '#f8f9fa',
    200: '#f0f2f5',
    300: '#e5e5eb',
    400: '#ced4da',
    500: '#adb5bd',
    600: '#6c757d',
    700: '#495057',
    800: '#343a40',
    900: '#212529',
  },
  gradients: {
    primary: {
      main: '#EC407A',
      state: '#D81B60',
    },
    secondary: {
      main: '#747b8a',
      state: '#495361',
    },
    info: {
      main: '#49a3f1',
      state: '#1A73E8',
    },
    success: {
      main: '#66BB6A',
      state: '#43A047',
    },
    warning: {
      main: '#FFA726',
      state: '#FB8C00',
    },
    error: {
      main: '#EF5350',
      state: '#E53935',
    },
    light: {
      main: '#EBEFF4',
      state: '#CED4DA',
    },
    dark: {
      main: '#42424a',
      state: '#191919',
    },
  },
  coloredShadows: {
    primary: '#e91e62',
    secondary: '#110e0e',
    info: '#00bbd4',
    success: '#4caf4f',
    warning: '#ff9900',
    error: '#f44336',
    light: '#adb5bd',
    dark: '#404040',
  },
  inputBorderColor: '#d2d6da',
  tabs: {
    indicator: { boxShadow: '#ddd' },
  },
};
