import React from 'react';
import { Container, Typography } from '@mui/material';

function TermsConditions() {
  return (
    <Container disableGutters maxWidth="md" component="main" sx={{ pt: 8, pb: 6 }}>
      <Typography component="h2" color="dark" gutterBottom>
        All Eyes On Me - Conditions d&apos;utilisation
      </Typography>
      <hr />
      <Typography fontWeight="light" color="dark" fontSize="16px" component="p" mt={2} mb={2}>
        JBBD SARL-S est une société privée à responsabilité limitée simplifié de droit
        luxembourgeois, et immatriculée au Registre de Commerce et des Sociétés de Luxembourg sous
        le numéro B277575, dont le siège social est situé au 9, rue du laboratoire, L1911,
        Luxembourg (ci-après dénommée <strong>&quot;All Eyes On Me&quot;</strong>,{' '}
        <strong>&quot;nous&quot;</strong> ou <strong>&quot;notre&quot;</strong>). Le site
        alleyesonme.jobs, édité par la société JBBD SARL-S, diffuse des offres d’emploi de sociétés
        tierces auxquelles des internautes peuvent directement postuler. La Société met donc en
        relation des recruteurs, des entreprises et des candidats.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Les présentes conditions d&apos;utilisation (les <strong>&quot;Conditions&quot;</strong>)
        entre vous et All Eyes On Me régissent votre accès et votre utilisation du site All Eyes On
        Me. Dans les présentes Conditions, les termes <strong>&quot;vous&quot;</strong>,{' '}
        <strong>&quot;votre&quot;</strong> et <strong>&quot;Client&quot;</strong> font référence à
        la personne physique ou morale qui s&apos;abonne au site All Eyes On Me et qui accepte les
        présentes conditions d&apos;utilisation afin d&apos;accéder au site All Eyes On Me et de
        l&apos;utiliser, soit en son nom propre, soit au nom de la personne morale contractant avec
        All Eyes On Me.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        En acceptant ces conditions, vous acceptez d&apos;être lié et de respecter ses termes et
        conditions. Votre utilisation du site All Eyes On Me indique que vous continuez à accepter
        les présentes Conditions. Vous reconnaissez que l&apos;acceptation électronique des
        présentes Conditions a la même force et les mêmes effets que si ces Conditions étaient
        signées physiquement.
      </Typography>

      <Typography color="dark" component="h3" fontWeight="bold" mt={3} mb={2}>
        1. Définition et interprétation
      </Typography>
      <Typography color="dark" component="h4" fontWeight="light" mt={3} mb={2}>
        1.1. Définition
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Lorsqu&apos;ils sont utilisés dans les présentes Conditions, les termes suivants ont les
        significations suivantes :
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        <strong>&quot;Candidat &quot;</strong> désigne la personne physique qui soumet tout type de
        candidature à un emploi ou à un stage au Client via All Eyes On Me ;
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        <strong>&quot;Documentation &quot;</strong> désigne les instructions d&apos;installation,
        d&apos;utilisation et de maintenance, les rapports, les documents de préparation, délivrés
        avec le site All Eyes On Me, quel que soit leur support ou leur format ;
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        <strong>&quot;Données du Client &quot;</strong> désigne l&apos;ensemble des données, de la
        documentation, du contenu ou des informations soumises par ou au nom du Client dans le but
        d&apos;exécuter les Conditions, ou dans le cadre de l&apos;utilisation du site All Eyes On
        Me, y compris, sans s&apos;y limiter, les données personnelles, le design audiovisuel (tels
        que les images photographiques, les animations, les illustrations) ou tout autre élément
        d&apos;identification ou de marque du Client, de ses clients ou des Candidats ;
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        <strong>&quot;Données personnelles &quot;</strong> a la signification qui lui est donnée en
        vertu du RGPD ;&quot;Droits de Propriété Intellectuelle&quot; désignent, sans limitation et
        le cas échéant, tous les brevets présents et futurs, les modèles d&apos;utilité, les idées
        et les droits sur les inventions (qu&apos;elles soient ou non brevetables), les
        améliorations, tous les droits d&apos;auteur et droits voisins (y compris, sans limitation,
        les droits moraux, ainsi que les droits de reproduction, de distribution, de communication
        au public, de location et de prêt), les marques (enregistrées ou non), les marques de
        service, les droits sur les dessins ou modèles (enregistrés ou non), les noms de produit,
        les logos, les slogans, les noms commerciaux, les noms de domaine, les droits sur les bases
        de données, les droits sur les informations confidentielles et/ou exclusives (y compris,
        sans s&apos;y limiter, le savoir-faire et les secrets d’affaire), les droits sur les
        logiciels (y compris, sans s&apos;y limiter, les algorithmes, les interfaces de
        programmation d&apos;applications, le code source, le code exécutable et le code objet, les
        appareils, les conceptions et les assemblages de circuits), les configurations et les
        architectures de réseaux, les concepts, les plans de marketing et de développement, les
        méthodes et tous les autres droits de propriété intellectuelle, dans chaque cas, qu&apos;ils
        soient enregistrés ou non, y compris toutes les demandes et tous les renouvellements ou
        extensions de ces droits, et tous les droits similaires ou équivalents ou toutes les formes
        de protection sans restriction géographique. Pour éviter toute ambiguïté, les Droits de
        Propriété Intellectuelle incluent et couvrent également toutes les Versions Mise à Jour, les
        Versions Améliorés, les mises à niveau de version, améliorations, perfectionnements,
        configurations, extensions et œuvre dérivés de ce qui précède et de la Documentation ou des
        manuels associés ;
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        <strong>&quot;Equipement&quot;</strong> désigne l&apos;ensemble des matériels, équipements
        informatiques, de communication et de réseau, connexions intranet, internet, unités
        centrales de traitement, serveurs, logiciels, placés sous votre entière responsabilité ;
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        <strong>&quot;Événement de Force Majeure&quot;</strong> désigne tout événement imprévisible,
        irrésistible et extérieurs et empêchant la Partie affectée d&apos;exécuter ses obligations
        en vertu des Conditions, en tout ou en partie sont notamment considéré comme force majeurs:
        les pannes de courant, les dysfonctionnements techniques de tiers, les épidémies, les
        pandémies, les quarantaines, les émeutes, les guerres, les actes de terrorisme, les
        incendies, les inondations, les tempêtes ou les tremblements de terre et toute catastrophe
        naturelle ;
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        <strong>&quot;Événement d&apos;Insolvabilité&quot;</strong> signifie à l&apos;égard
        d&apos;une Partie :
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        a) conclure ou décider de conclure un arrangement, un concordat, un compromis ou une cession
        au profit de ses créanciers ou de toute catégorie d&apos;entre eux dans toute juridiction
        pertinente, à des conditions qui ne relèvent pas du cours normal des affaires ;
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        b) être dans l&apos;incapacité de payer ses dettes lorsqu&apos;elles sont exigibles, ou
        suspendre le paiement de la totalité ou d&apos;une partie importante de ses dettes, ou
        annoncer publiquement par écrit son intention de le faire, ou être considéré comme
        insolvable en vertu de toute disposition légale de toute juridiction concernée ;
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        c) la nomination d&apos;un liquidateur ou d&apos;un liquidateur provisoire ou la nomination
        d&apos;un administrateur, d&apos;un gérant, d&apos;un fiduciaire ou d&apos;un fonctionnaire
        similaire sur l&apos;un des actifs ou des entreprises de la Partie (y compris la nomination
        d&apos;un administrateur judiciaire, d&apos;un administrateur, d&apos;un fiduciaire,
        d&apos;un dépositaire, d&apos;un liquidateur provisoire, ou d&apos;un fonctionnaire
        similaire comprend, sans limitation, un juge délégué, un commissaire, un juge-commissaire,
        un mandataire ad hoc, un administrateur provisoire ou un curateur au Luxembourg, ou un
        événement analogue à un tel événement survenant dans toute juridiction compétente ;
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        d) une demande, une ordonnance ou une résolution faite ou adoptée pour la liquidation de la
        partie (y compris, sans limitation, la faillite, l&apos;insolvabilité, la liquidation, le
        concordat préventif de la faillite, le moratoire ou le sursis de paiement, la gestion
        contrôlée, le règlement général avec les créanciers, la réorganisation ou des lois
        similaires affectant les droits des créanciers en général) ; ou
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        e) tout événement analogue se produisant dans n&apos;importe quelle juridiction ;
        &quot;Informations Confidentielles&quot; désigne (i) toute information, quelle que soit sa
        forme, notamment, toute information, donnée technique ou savoir-faire se rapportant à des
        découvertes, idée, invention, concept, logiciel, équipement, projet, dessin, spécification
        technique, procédé, système, modèle, donnée, code source, code objet, documentation,
        diagramme, organigramme, recherche, développement, plan d’entreprise ou opportunité,
        stratégie commerciale, plan de commercialisation ou opportunité, stratégie de
        commercialisation, projet ou produit futur, projet ou produit à l’étude, procédure et
        information relatives aux finances, coûts, prix, fournisseurs, vendeurs, clients et
        salariés, divulguées par l’une ou l’autre Partie ou pour son compte, directement ou
        indirectement, par écrit, oralement ou par dessin ou inspection d’équipement ou de logiciel,
        à l’autre Partie ou à l’un de ses salariés ou agents ; (ii) toute autre information soit
        divulguées par écrit à l’autre Partie ou l’un de ses salariés ou agents, soit divulguée par
        un autre mode de communication.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        <strong>&quot;Jour Ouvrable&quot;</strong> désigne les jours d’ouvertures au public des
        établissements bancaires au Luxembourg ;
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        <strong>&quot;site All Eyes On Me&quot;</strong> désigne les sites standards mis à
        disposition par All Eyes On Me en logicel en tant que service;
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        <strong>&quot;Lois sur la Protection des Données&quot;</strong> désigne les dispositions de
        toute loi nationale applicable en matière de protection des données (y compris, mais sans
        s&apos;y limiter, la loi luxembourgeoise du 1er août 2018 relative à l&apos;organisation de
        la Commission nationale pour la protection des données et au régime général de la protection
        des données, telle qu&apos;elle peut être modifiée ou remplacée) et le RGPD ;
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        <strong>&quot;Parties&quot;</strong> désignent All Eyes On Me et/ou le Client ;
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        <strong>&quot;RGPD&quot;</strong> désigne le Règlement n°2016/679 du 27 avril 2016 relatif à
        la protection des personnes physiques à l&apos;égard du traitement des données à caractère
        personnel et à la libre circulation de ces données ;
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        <strong>&quot;Sous-Traitant Agréé&quot;</strong> désigne (i) une entité qui, directement ou
        indirectement, possède ou contrôle, est possédée ou contrôlée par, ou est sous contrôle
        commun de All Eyes On Me (le contrôle étant défini comme la possession, directement ou
        indirectement, du pouvoir de diriger ou de faire diriger la gestion et les politiques
        d&apos;une entité, que ce soit par la propriété de titres avec droit de vote, par contrat ou
        autrement), (ii) tout tiers auquel All Eyes On Me a, à la date des conditions, délégué ou
        externalisé tout ou partie des services et/ou transféré les Données Personnelles
        conformément aux Conditions et (iii) tout tiers auquel All Eyes On Me souhaite déléguer le
        traitement des Données Personnelles conformément aux Conditions, sous réserve de
        l&apos;accord écrit préalable du Client ;
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        <strong>&quot;Version Améliorée&quot;</strong> désigne toute version du site All Eyes On Me
        fournie dans le cadre des présentes Conditions, qui intègre des améliorations majeures et/ou
        de nouvelles fonctionnalités ;
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        <strong>&quot;Version Mise à Jour&quot;</strong> désigne toute version du site All Eyes On
        Me, intégrant des corrections d&apos;erreurs et/ou des améliorations.
      </Typography>
      <Typography color="dark" component="h4" fontWeight="light" mt={3} mb={2}>
        1.2. Interprétation
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Dans ces Conditions :
      </Typography>
      <ul>
        <li>
          {' '}
          <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
            toute référence à un accord ou un contrat doit être interprétée comme une référence à
            cet accord ou ce contrat tel qu&apos;il peut être amendé, complété ;
          </Typography>
        </li>
        <li>
          {' '}
          <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
            une référence à une ou plusieurs personnes est, le cas échéant, réputée être une
            référence à leurs successeurs, cessionnaires autorisés ou cessionnaires respectifs,
            selon le cas ;
          </Typography>
        </li>
        <li>
          {' '}
          <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
            les références aux clauses et aux annexes sont des références, respectivement, aux
            clauses et aux annexes des présentes Conditions et la référence aux présentes
          </Typography>
        </li>
      </ul>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Conditions inclut ses annexes le cas échéant ;
      </Typography>
      <ul>
        <li>
          {' '}
          <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
            toute référence à une loi ou à un règlement ou à l&apos;une de leurs dispositions doit
            être interprétée comme une référence à cette loi, ce règlement ou ces dispositions, tels
            qu&apos;ils ont pu être, ou pourront être, modifiés ou ré-adoptés ;
          </Typography>
        </li>
        <li>
          {' '}
          <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
            les mots désignant le singulier incluent le pluriel et vice versa ;
          </Typography>
        </li>
        <li>
          {' '}
          <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
            les mots désignant des personnes comprennent les personnes morales, les sociétés de
            personnes, les associations et tout autre groupe organisé de personnes ou
            d&apos;entités, qu&apos;il soit constitué ou non.
          </Typography>
        </li>
      </ul>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Les titres des clauses sont destinés à faciliter les références et ne doivent pas impacter
        l&apos;interprétation des Conditions.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        En cas de conflit ou d&apos;incohérence, l&apos;ordre de préséance suivant s&apos;applique,
        sauf mention expresse contraire :
      </Typography>
      <ul>
        <li>
          {' '}
          <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
            l&apos;Offre ;
          </Typography>
        </li>
        <li>
          {' '}
          <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
            les Conditions
          </Typography>
        </li>
      </ul>
      <Typography color="dark" component="h3" fontWeight="bold" mt={3} mb={2}>
        2. Objet des Conditions
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Sous réserve des dispositions des présentes Conditions et du paiement du Prix, All Eyes On
        Me accorde au Client un droit non transférable, non sous-licenciable et non exclusif
        d&apos;utiliser le site All Eyes On Me et la Documentation.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Le Client ne dispose d&apos;aucun droit, réclamation ou intérêt de quelque nature que ce
        soit concernant le site All Eyes On Me autre que ceux accordés en vertu des présentes
        Conditions.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        All Eyes On Me et ses partenaires techniques ou employés sont autorisés à examiner le site
        All Eyes On Me à tout moment sans consentement préalable du Client dans le cadre des
        opérations de maintenance, de l&apos;installation de correctifs et du support client.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Sous réserve du respect des clauses 7 et 8 ci-dessous, le Client autorise expressément All
        Eyes On Me à analyser le site All Eyes On Me pour recueillir des informations relatives à
        son utilisation par le Client afin d&apos;améliorer ses produits et services.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Le Client est informé que, dans le cadre de l&apos;utilisation du site All Eyes On Me, il
        peut se voir accorder l&apos;accès à des fonctionnalités fournies par des tiers (tels que,
        Skeeled, Jooined,ou autres). Dans ce cas, le Client est seul responsable de
        l&apos;acceptation et du respect de toutes les conditions d&apos;utilisation ou autres
        dispositions contractuelles liées à ces fonctionnalités fournies par des tiers et All Eyes
        On Me ne peut être tenu responsable de tout manquement du Client.
      </Typography>
      <Typography color="dark" component="h3" fontWeight="bold" mt={3} mb={2}>
        3. Propriété intellectuelle
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        All Eyes On Me est et reste le propriétaire exclusif et/ou l&apos;utilisateur autorisé de
        tout Droit de Propriété Intellectuelle sur, sans limitation, (i) toutes les marques All Eyes
        On Me apparaissant sur le site All Eyes On Me, (ii) l&apos;interface utilisateur graphique
        du site All Eyes On Me, (iii) tous les sites utilisés dans ou en relation avec le site All
        Eyes On Me, (iv) tout document susceptible d&apos;être protégé par un Droit de Propriété
        Intellectuelle et (v) toutes les Versions Améliorées, les Versions Mise à Jour, les
        améliorations, perfectionnements, configurations, extensions et œuvres dérivées du site All
        Eyes On Me (même si elles résultent de l&apos;utilisation du site All Eyes On Me par le
        Client).
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Certains composants du site All Eyes On Me peuvent être fournis par des tiers, et peuvent
        être soumis à des conditions spécifiques. Ces composants tiers sont la propriété
        intellectuelle des fournisseurs spécifiques, et en tant que tels, aucun droit, titre ou
        intérêt, autre que le droit de les utiliser dans le cadre du site All Eyes On Me tel que
        décrit dans les présentes Conditions, ne sera dévolu au Client.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Vous comprenez et acceptez que votre utilisation du site All Eyes On Me ne vous confère
        aucun droit sur les Droits de Propriété Intellectuelle de All Eyes On Me et que toute
        utilisation des Droits de Propriété Intellectuelle de All Eyes On Me sans son consentement
        préalable écrit peut vous exposer à des sanctions, y compris, mais sans s&apos;y limiter,
        l’interdiction de votre accès au site All Eyes On Me, ainsi qu&apos;à d’éventuelles actions
        en justice pouvant donner lieu à l’indemnisation, en cas de dommage subi par All Eyes On Me,
        suite à une violation de ses Droits de Propriété Intellectuelle.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Il est également interdit au Client sauf avec l’autorisation écrite et explicit de All Eyes
        On Me durant la période où le Client est sous licence, de quelque manière que ce soit et sur
        quelque support que ce soit, en tout ou partie, de :
      </Typography>
      <ul>
        <li>
          <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
            modifier, copier, traduire, adapter, reproduire, distribuer, diffuser, transférer ou
            créer une œuvre dérivée sur la base de tout élément de All Eyes On Me et de son contenu
            ;
          </Typography>
        </li>
        <li>
          <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
            créer des liens internet vers ou depuis All Eyes On Me, ou produire des cadres ou des
            sites &quot;miroirs&quot; contenant tout ou partie du site All Eyes On Me, même à des
            fins exclusivement internes ;
          </Typography>
        </li>
        <li>
          <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
            le désassemblage, l&apos;ingénierie inverse ou la décompilation du site All Eyes On Me
            ou de tout ou partie de son contenu, ou le simple fait d&apos;y accéder et de
            l&apos;utiliser dans le but de (a) créer un produit ou un service concurrent, (b) créer
            un produit ou un service en utilisant les idées, les caractéristiques, les fonctions ou
            les graphiques du siteAll Eyes On Me ; ou
          </Typography>
        </li>
        <li>
          <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
            la suppression, l&apos;altération, la couverture ou la modification de toute marque de
            commerce, de tout droit d&apos;auteur ou de tout autre avis de propriété, de toute
            étiquette ou de tout marquage de ou lié au site All Eyes On Me ou à toute autre
            caractéristique, de quelque nature que ce soit, développé et/ou appartenant à All Eyes
            On Me.
          </Typography>
        </li>
      </ul>
      <Typography color="dark" component="h3" fontWeight="bold" mt={3} mb={2}>
        4. Réclamations de tiers
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        All Eyes On Me tiendra le Client quitte et indemne de toute réclamation ou action d’un tiers
        (y compris l&apos;indemnisation des honoraires et des frais de justice) découlant d&apos;une
        allégation selon laquelle l&apos;utilisation, l&apos;exploitation ou la distribution du site
        All Eyes On Me ou d&apos;une partie de celui-ci enfreint les Droits de Propriété
        Intellectuelle d&apos;un tiers.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Dans le cas d&apos;une réclamation d&apos;un tiers à l&apos;encontre du Client alléguant que
        l&apos;utilisation par le Client du site All Eyes On Me enfreint les Droits de Propriété
        Intellectuelle d&apos;un tiers (la &quot;réclamation&quot;), All Eyes On Me défendra le
        Client, à ses frais contre toute action engagée contre le Client sur la base de ladite
        réclamation, et paiera tout montant ordonné ou frais et dommages-intérêts accordés par une
        décision exécutoire, y compris, le cas échéant, les frais d&apos;avocat raisonnables engagés
        à cette fin, à condition que :
      </Typography>
      <ul>
        <li>
          <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
            le Client notifie par écrit à All Eyes On Me, dès que cela est raisonnablement possible,
            toute réclamation dont il a connaissance ;
          </Typography>
        </li>
        <li>
          <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
            le Client ne doit pas faire de reconnaissance de responsabilité, de compromis ou
            d&apos;accord de règlement de toute réclamation sans le consentement écrit préalable de
            All Eyes On Me ;
          </Typography>
        </li>
        <li>
          <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
            le Client permet à All Eyes On Me d&apos;assumer, à ses propres frais, la conduite
            exclusive ou le droit de régler toutes les négociations et tous les litiges découlant de
            toute réclamation et le Client fournit à All Eyes On Me toute l&apos;assistance requise
            dans le cadre de ces négociations et de ces litiges à la demande et aux frais de All
            Eyes On Me.
          </Typography>
        </li>
      </ul>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Si, à la suite d&apos;une telle réclamation, le Client est définitivement empêché
        d&apos;utiliser All Eyes On Me ou une partie de celui-ci, All Eyes On Me peut, à sa seule
        discrétion et à ses frais, obtenir pour le Client le droit de continuer à utiliser All Eyes
        On Me et/ou une partie de celui-ci, ou il peut fournir un remplacement ou une modification
        de All Eyes On Me, d&apos;un composant ou d&apos;une partie de celui-ci de manière à régler
        ladite réclamation.
      </Typography>
      <Typography color="dark" component="h3" fontWeight="bold" mt={3} mb={2}>
        5. Limitation de la responsabilité
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Rien dans les Conditions, et en particulier dans la présente clause de limitation de
        responsabilité, ne peut exclure ou limiter la responsabilité (i) en cas de fraude ; (ii)
        pour ce qui n&apos;est pas autorisé en vertu de la loi ; (iii) en cas de violation par le
        Client des Droits de Propriété Intellectuelle de All Eyes On Me, et (iv) des obligations de
        confidentialité prévues par les présentes Conditions.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Aucune des Parties ne peut être tenue responsable, dans le cadre des obligations qui lui
        incombent en vertu des présentes Conditions, de dommages spéciaux, indirects ou accessoires,
        de dommages (directs ou indirects) pour perte de clientèle, atteinte à la réputation,
        interruption d&apos;activité ou perte d&apos;opportunité.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Toute autre responsabilité de All Eyes On Me résulant d’un défaut d’exécution des
        obligations lui incombant en vertu des présentes Conditions, sera limitée et ne pourra
        dépasser la valeur du contrat de couverture de All Eyes On Me / OU ne pourra dépasser
        l’indemnisation du préjudice direct subi par le Client.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        La présente clause 5 ne s&apos;applique pas à toute réclamation de All Eyes On Me relative
        au Prix dû par le Client en vertu des Conditions.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        All Eyes On Me ne peut en aucun cas être tenu responsable d&apos;un quelconque défaut et
        décline toute garantie quant aux fonctionnalités ou applications fournies par des tiers et
        auxquelles le Client peut avoir accès par le biais du site All Eyes On Me. Dans ce cas, le
        recours du Client se fera directement contre le tiers dans la limite des conditions
        d&apos;utilisation de ce dernier telles qu&apos;acceptées par le Client.
      </Typography>
      <Typography color="dark" component="h3" fontWeight="bold" mt={3} mb={2}>
        6. Représentations et garanties
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Chaque Partie déclare et garantit à l&apos;autre Partie que :
      </Typography>
      <ul>
        <li>
          <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
            elle dispose de toutes les licences, permis et autorisations nécessaires (y compris,
            lecas échéant, le consentement de sa société mère ou les autorisations réglementaires
            nécessaires) pour conclure et exécuter ses obligations en vertu des présentes Conditions
            ;
          </Typography>
        </li>
        <li>
          <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
            elle n&apos;est pas soumise à des Evénements d&apos;Insolvabilité ;
          </Typography>
        </li>
        <li>
          <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
            il n&apos;existe aucun accord existant avec des tiers qui l&apos;empêcherait de remplir
            les obligations découlant des présentes Conditions ;
          </Typography>
        </li>
        <li>
          <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
            elle se conforme à toutes les lois et dispositions spécifiques au pays dans lequel elle
            exerce ses activités ;
          </Typography>
        </li>
        <li>
          <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
            elle est légalement constituée et elle a le pouvoir et l&apos;autorité pour exécuter les
            présentes Conditions et remplir ses obligations en vertu des présentes ;
          </Typography>
        </li>
        <li>
          <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
            toutes les mesures nécessaires ont été prises pour lui permettre de s&apos;engager
            valablement et d&apos;exécuter ses obligations en vertu des présentes Conditions et
            l&apos;exécution de ces obligations ne constitue pas une violation de ses documents
            constitutifs ou des conditions d’actionnariat ;
          </Typography>
        </li>
        <li>
          <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
            une fois dûment exécutées, les présentes Conditions constitueront des obligations
            légales, valides et contraignantes qui lui incombent.
          </Typography>
        </li>
      </ul>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Le Client assume l&apos;entière responsabilité de l&apos;acquisition, de la maintenance, de
        l&apos;efficacité globale et du rendement de l&apos;Equipement sur lequel le site All Eyes
        On Me doit fonctionner.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Les garanties de All Eyes On Me relatives aux Droits de Propriété Intellectuelle ne
        s&apos;appliquent pas si la violation présumée a été causée par a) toute modification non
        autorisée de All Eyes On Me, b) une utilisation qui est contraire de manière importante aux
        instructions spécifiquement définies dans la Documentation ou dans les présentes Conditions,
        ou c) toute modification du site All Eyes On Me effectuée par le Client ou par un tiers sans
        le consentement écrit préalable de All Eyes On Me.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        All Eyes On Me ne peut en aucun cas être tenu responsable des informations téléchargées et
        de l&apos;utilisation par le Client du site All Eyes On Me. Le Client déclare et garantit
        qu&apos;il utilisera le site All Eyes On Me en conformité avec toutes les lois et
        réglementations applicables.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Les garanties énoncées dans le présent document sont des garanties limitées et sont les
        seules garanties données par All Eyes On Me. All Eyes On Me ne donne pas, et le Client
        renonce expressément par la présente, à toutes les autres garanties commerciales et
        d&apos;adéquation de All Eyes On Me à un usage particulier.
      </Typography>
      <Typography color="dark" component="h3" fontWeight="bold" mt={3} mb={2}>
        7. Confidentialité
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Chacune des Parties doit à tout moment garder strictement confidentielles les Informations
        Confidentielles qu&apos;elle peut acquérir avant ou après la date des présentes Conditions
        et ne doit pas utiliser ou divulguer ces informations, sauf avec le consentement écrit
        préalable de l&apos;autre Partie.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Les Parties conviennent de traiter ces Conditions de manière confidentielle et ne
        distribueront pas, ne reproduiront pas, ne copieront pas et ne divulgueront pas, en tout ou
        en partie, ces Conditions ou leur contenu. Les Parties ne sont pas non plus autorisées à
        divulguer l&apos;existence des présentes Conditions ou de tout autre arrangement connexe
        entre les Parties, à moins d&apos;avoir obtenu l&apos;accord écrit préalable de l&apos;autre
        Partie.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Nonobstant ce qui précède, l&apos;une des Parties peut divulguer ou utiliser les
        Informations Confidentielles visées dans la présente clause dans la mesure où :
      </Typography>
      <ul>
        <li>
          <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
            la divulgation ou l&apos;utilisation est exigée par la loi, par toute autorité
            gouvernementale ou réglementaire ou autrement dans le cadre de toute procédure
            judiciaire ou administrative (y compris en réponse à des questions orales, des
            interrogatoires ou des demandes d&apos;informations ou de documents) ;
          </Typography>
        </li>
        <li>
          <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
            la divulgation est requise « on a need-to-know basis » par une société affiliée ou un
            représentant légal de cette Partie, les auditeurs ou les conseillers professionnels de
            cette Partie ou de ses sociétés affiliées, à condition que les destinataires soient liés
            par des obligations contractuelles ou légales de confidentialité et que la partie
            divulgatrice prenne la responsabilité de s&apos;assurer que ces obligations de
            confidentialité soient respectées.
          </Typography>
        </li>
      </ul>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Avant toute divulgation d&apos;Informations Confidentielles sur la base du paragraphe
        ci-dessus, la Partie divulgatrice informera l&apos;autre Partie de cette intention au moins
        cinq (5) Jours Ouvrables à l&apos;avance.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Chaque Partie reconnaît explicitement qu&apos;elle sera entièrement et uniquement
        responsable de tout dommage résultant d&apos;une violation de ses obligations de
        confidentialité en vertu des présentes Conditions.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        L&apos;obligation de confidentialité des Parties en vertu des présentes Conditions survivra
        à la résiliation ou l’expiration des Conditions pendant une période de 10 ans.
      </Typography>
      <Typography color="dark" component="h3" fontWeight="bold" mt={3} mb={2}>
        8. Propriété et protection des données
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        All Eyes On Me procède, en qualité de responsable du traitement, à des traitements de
        données à caractère personnel portant sur les collaborateurs du Client et candidats du
        Client avec qui celle-ci est en relation aux fins de l’exécution du Contrat, comprenant la
        présentation des Services, la contractualisation, le suivi de la relation commerciale et la
        facturation.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Ces données peuvent également être traitées par All Eyes On Me pour répondre à ses
        obligations administratives, fiscales et comptables ainsi que pour la défense de ses
        intérêts en cas de litige. Les traitements de données à caractère personnel mis en œuvre
        sont réalisés pendant toute la durée de la relation contractuelle et les données sont
        conservées conformément aux durées légales applicables notamment en matière commerciale,
        comptable et fiscale.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Toute personne concernée peut exercer ses droits au titre de la Réglementation Données
        Personnelles, notamment ses droits d’accès, de rectification, de suppression, et plus
        généralement obtenir des informations sur la politique de protection des données à caractère
        personnel de la Société en écrivant à : privacy@alleyesonme.jobs.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Sous réserve de l&apos;anonymisation complète de toute donnée issue de l’utilisation de ses
        Services, All Eyes On Me peut être amenée à agréger, analyser et enrichir ces données pour
        (i) fournir les Services et améliorer leur disponibilité ; (ii) développer de nouvelles
        fonctionnalités, de nouveaux produits et services ; et (iii) établir des rapports
        statistiques anonymes et enregistrer des informations sur les modèles d&apos;utilisation.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        All Eyes On Me est amenée, dans le cadre de la fourniture des Services et à la demande et
        pour le compte du Client, à procéder à des traitements de données à caractère personnel en
        qualité de sous-traitant au sens de la Réglementation Données Personnelles. Ces traitements
        sont encadrés par l’Annexe RGPD, laquelle fait partie intégrante du Contrat.
      </Typography>
      <Typography color="dark" component="h3" fontWeight="bold" mt={3} mb={2}>
        9. Droit unilatéral de modification
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        All Eyes On Me se réserve le droit de modifier unilatéralement, à tout moment, tout ou
        partie de All Eyes On Me, notamment en cas d&apos;évolution technique, légale ou
        jurisprudentielle ou lors de la mise en place de nouveaux services. All Eyes On Me peut, par
        exemple, modifier les visuels, les chartes graphiques et autres éléments et/ou contenus du
        All Eyes On Me à sa seule discrétion.
      </Typography>
      <Typography color="dark" component="h3" fontWeight="bold" mt={3} mb={2}>
        10. Prix et paiement
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Le prix à payer par le Client pour l&apos;utilisation de All Eyes On Me (le « Prix »)
        pendant un (1)an est spécifié dans l’Offre.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Le Prix, y compris tous les montants indiqués dans l’Offre , sont exprimés en euros (EUR),
        hors taxe sur la valeur ajoutée ou toute autre taxe, droit ou prélèvement. La taxe sur la
        valeur ajoutée ou toute autre taxe, droit ou prélèvement, le cas échéant, s&apos;ajoutera et
        sera facturée au Client au moment de l&apos;émission des factures au Client.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Le Prix est soumis à des ajustements en fonction des fluctuations de l&apos;indice national
        des prix à la consommation (IPCN) tel que publié par l&apos;Institut national de la
        statistique et des études économiques du Grand-Duché de Luxembourg (STATEC). La variation
        sera appliquée chaque année, à chaque date anniversaire du contrat, sur la base du dernier
        indice publié annuellement.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        All Eyes On Me facture l’entièreté du Prix au Client à la date de signature de l’Offre («
        Proposal »). Chaque facture est due et payable dans les trente (30) jours suivant sa
        réception par le Client et ce dernier doit régler immédiatement le montant total de chaque
        facture.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Le Client est responsable de la mise à jour de son adresse légale ainsi que de celle de son
        ou ses principales personnes de contact. Les coordonnées fournies par le Client dans l’Offre
        seront considérées comme le contact principal pour la facturation. Un retard dans la
        réception de la facture dû à l&apos;absence d&apos;information de All Eyes On Me sur un
        changement de coordonnées ne sera pas une cause de retard dans le paiement de la facture par
        le Client comme spécifié dans les présentes Conditions.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        A défaut d&apos;envoi d&apos;une lettre recommandée avec accusé de réception de protestation
        dans un délai d&apos;un (1) mois à compter de la date de reception de la facture par le
        Client, celle-ci est réputée définitivement et irrévocablement acceptée par le Client.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        En cas de non respect du Client de son obligation de payer le Prix dans le délai
        susmentionné, All Eyes On Me se réserve le droit de réclamer au Client le paiement des
        intérêts vissé par la loi Luxembourgeoise du 18 avril 2004 relative aux délais de paiement
        et intérêts de retard, sans en informer préalablement le Client.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Tout retard de paiement d&apos;une facture par le Client conformément aux présentes
        Conditions donnera automatiquement, de suspendre la fourniture du site All Eyes On Me en
        tout ou en partie sous condition de notifier préalablement et par écrit la suspension au
        Client. All Eyes On Me utilisera à sa discrétion les efforts raisonnables pour rappeler au
        Client les factures à payer avant de suspendre la fourniture du site All Eyes On Me. Afin de
        lever toute ambiguïté, cette information préalable ne sera toutefois pas considérée comme
        une mise en demeure.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        All Eyes On Me prendra toutes les mesures raisonnables pour mettre le site All Eyes On Me, y
        compris tout support y afférent, à la disposition du Client dans les meilleurs délais à
        compter de la date de signature du contrat par le Client.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        En cas de suspension de l‘accès au site All Eyes On Me en vertu des présentes Conditions,
        All Eyes On Me prendra toutes les mesures nécessaires pour remettre le site de All Eyes On
        Me à la disposition du Client dans les meilleurs délais après le règlement intégral du Prix
        et des factures impayées par le Client.
      </Typography>
      <Typography color="dark" component="h3" fontWeight="bold" mt={3} mb={2}>
        11. Durée et résiliation
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Les Conditions sont conclues pour une durée d&apos;un (1) an à compter de la date de l’Offre
        (« Proposal »), sauf indication contraire, et sont tacitement renouvelables d&apos;année en
        année.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Chaque Partie peut résilier les Conditions par lettre recommandée avec accusé de réception
        notifier l’autre Partie au plus tard soixante (60) jours avant la prochaine date
        anniversaire.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        En cas de manquement par l’une des Parties de quelconque des obligations lui incombant en
        vertu des présentes Conditions, l’autre partie disposera de la faculté de mettre fin aux
        présentes, sans délais, en notifiant l’autre Partie son intention par lettre recommandée et
        en indiquant l’obligation violée. La résiliation prendra effet à compté de la date de
        reception par l’autre Partie.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Chaque Partie a également le droit de résilier les présentes Conditions si l&apos;autre
        Partie fait l&apos;objet d&apos;un Evènement d’Insolvabilité.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        En cas de résiliation anticipée des Conditions suite à la violation par All Eyes On Me de
        ses obligations en vertu des présentes Conditions, All Eyes On Me remboursera au Client la
        partie du Prix payée d&apos;avance par le Client et couvrant la période pendant laquelle le
        site All Eyes On Me n&apos;a pas été fourni. En cas de résiliation prématurée des Conditions
        en raison de la violation par le Client de ses obligations en vertu des Conditions, le
        Client est tenu de payer (i) l&apos;ensemble du Prix jusqu&apos;à la date de résiliation.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Nonobstant toute résiliation des Conditions, les clauses 3 à 6, 13 et 15 des Conditions
        survivront à la résiliation des présentes Conditions pendant une période de dix (10) ans.
      </Typography>
      <Typography color="dark" component="h3" fontWeight="bold" mt={3} mb={2}>
        12. Assistance et mises à jour
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        All Eyes On Me offrira au Client les Jours Ouvrables une assistance en ligne conforme aux
        pratiques du marché, comme indiqué dans le SLA. Les services d&apos;assistance offerts par
        All Eyes On Me se limitent exclusivement a All Eyes On Me et n&apos;incluent pas de services
        d&apos;assistance supplémentaires tels que ceux liés au matériel du Client ou d&apos;autres
        services de conseil. Les frais des services d&apos;assistance sont déjà inclus dans le Prix.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        All Eyes On Me peut fournir au Client une Version Mise à jour ou une Version Améliorée du
        All Eyes On Me. La simple possibilité pour All Eyes On Me de fournir une Version Mise à jour
        ou une Version Améliorée n&apos;implique toutefois aucune obligation pour All Eyes On Me de
        délivrer une telle Version Mise à jour ou Améliorée, et ne crée aucun droit légal pour le
        Client de recevoir une telle Version Mise à jour ou Améliorée.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        All Eyes On Me peut être en droit de demander le paiement de frais supplémentaires pour une
        Version Améliorée. Si le Client refuse de payer ces frais supplémentaires, il aura le droit
        de résilier les présentes Conditions (avec remboursement au Client de la partie du Prix payé
        d&apos;avance par le Client et couvrant la période pendant laquelle le site All Eyes On Me
        n&apos;a pas été fourni).
      </Typography>
      <Typography color="dark" component="h3" fontWeight="bold" mt={3} mb={2}>
        13. Référence commerciale
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Le Client autorise All Eyes On Me et accorde toute licence nécessaire pour utiliser les
        informations suivantes comme référence commerciale dans les publications, événements et
        actions visant à promouvoir les produits et services de All Eyes On Me : le nom du Client et
        les informations connues publiquement sur le Client (y compris la ou les marques ou logos du
        Client). Le Client peut révoquer cette autorisation à tout moment par écrit et sans aucune
        justification.
      </Typography>
      <Typography color="dark" component="h3" fontWeight="bold" mt={3} mb={2}>
        14. Cession
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        À l&apos;exception de ce qui est prévu ailleurs dans les présentes Conditions, les Parties
        ne peuvent transférer les présentes Conditions (ou toute partie de celles-ci) sans le
        consentement écrit de l&apos;autre Partie, qui ne pourra pas être refusé sans raison. Chaque
        Partie peut transférer les présentes Conditions dans le cadre d&apos;une fusion, d&apos;une
        réorganisation ou de la vente de la totalité ou de la quasi-totalité des actifs de la Partie
        cédante ou en cas de changement de contrôle. Les présentes Conditions engagent les
        successeurs et cessionnaires autorisés respectifs des Parties.
      </Typography>
      <Typography color="dark" component="h3" fontWeight="bold" mt={3} mb={2}>
        15. Non-sollicitation
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Les Parties s&apos;interdisent de solliciter, directement ou indirectement, tout salarié de
        l&apos;autre Partie ayant participé à l&apos;exécution des présentes Conditions, pendant
        toute leur durée des Conditions et pendant six (6) mois après leur expiration. En cas de
        violation de cette clause, la Partie non-défaillante pourra réclamer à la Partie défaillante
        une indemnité égale à douze (12) fois la dernière rémunération mensuelle brute du salarié
        débauché, dans un délai maximum de trois (3) mois suivant la connaissance par la Partie
        demanderesse du débauchage de ce salarié.
      </Typography>
      <Typography color="dark" component="h3" fontWeight="bold" mt={3} mb={2}>
        16. Force Majeure
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Aucune des Parties ne sera considérée comme ayant manqué à l&apos;une de ses obligations
        contractuelles en vertu des présentes Conditions si elle a été empêchée d&apos;exécuter
        ladite ou lesdites obligations contractuelles en raison de la survenance d’un Evènement de
        Force Majeure.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Les Parties prennent les mesures nécessaires pour atténuer les conséquences de
        l&apos;Evénement de Force Majeure concerné ainsi que les éventuels dommages qui en
        découlent.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Si un tel Evènement de Force Majeure se produit et empêche l&apos;une des Parties
        d&apos;exécuter tout ou partie de ses obligations contractuelles, ou peut raisonnablement
        affecter l&apos;exécution future de ses obligations contractuelles, alors cette Partie (i)
        en informera dûment l’autre Partie dès qu’elle en aura connaissance, en lui écrivant la
        nature, la durée probable et les effets prévisibles dudit Evènement de Force Majeure et (ii)
        prendra toutes les mesures et actions nécessaires pour atténuer les conséquences et/ou les
        effets résultant dudit Evènement de Force Majeure, y compris l&apos;intervention de tout
        tiers si cela est raisonnablement possible.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Dans l’hypothèse de la survenance d’un Evènement de Force Majeure, les obligations des
        Parties ne peuvent manifestement être exécutées seront suspendues pendant toute la durée de
        celui-ci et reprendront leur cours dès sa cessation. Les délais d’exécution des obligations
        suspendues seront prolongés d’une durée équivalente à la durée de l’Evenènement de Force
        Majeure.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Lorsqu&apos;il s&apos;avère que, malgré la mise en œuvre des mesures et actions
        susmentionnées, l&apos;exécution des Conditions est définitivement devenue impossible ou
        devra être reportée de plus de trente (30) jours à compter de la date de notification de
        l&apos;Evénement de Force Majeure, les présentes Conditions pourront être résiliées par
        l&apos;une ou l&apos;autre des Parties moyennant un préavis de trente (30) jours commençant
        à courir à partie de l’envoi de la lettre recommandée.
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        En tout état de cause, chaque Partie supportera les frais et dépenses qu&apos;elle a engagés
        depuis le début de l&apos;Evénement de Force Majeure jusqu&apos;à la fin dudit
        l&apos;Evénement de Force Majeure ou jusqu&apos;à la date de résiliation des Conditions.
      </Typography>
      <Typography color="dark" component="h3" fontWeight="bold" mt={3} mb={2}>
        17. Non-renonciation
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Aucun manquement ou retard d&apos;une Partie dans l&apos;exercice d&apos;un droit ou
        d&apos;un recours en vertu des présentes Conditions ne sera considéré comme une renonciation
        à ce droit ou à ce recours, et aucun exercice unique ou partiel d&apos;un droit ou d&apos;un
        recours n&apos;empêchera un autre exercice de ce droit ou de ce recours ou l&apos;exercice
        de tout autre droit ou recours.
      </Typography>
      <Typography color="dark" component="h3" fontWeight="bold" mt={3} mb={2}>
        18. Intégralité des Conditions
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Les présentes Conditions contiennent l&apos;intégralité de l&apos;accord des Parties quant à
        l&apos;objet des présentes Conditions et remplacent tous les accords antérieurs entre les
        Parties ayant le même objet.
      </Typography>
      <Typography color="dark" component="h3" fontWeight="bold" mt={3} mb={2}>
        19. Communications
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Toutes les notifications et autres communications en vertu des présentes Conditions seront
        faites par écrit et seront considérées comme ayant été dûment transmises (i) à la date de
        délivrance si elles sont remises personnellement à la Partie à laquelle la notification doit
        être donnée, ou (ii) le premier Jour Ouvrable après la délivrance via un service de courrier
        international, si elles sont correctement adressées et tous les frais prépayés.
      </Typography>
      <Typography color="dark" component="h3" fontWeight="bold" mt={3} mb={2}>
        20. Amendements
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Les présentes Conditions pourront faire l’objet d’une modification entre les Parties, sous
        forme d’un avenant conclu par celles-ci. Sauf dérogation expresse prévue par l’avenant, ce
        dernier produira ses effetes au premier jour du mois qui suit celui de sa conclusion. Tout
        avenant conclu par les Partie fera partie intégrante des présentes Conditions et sera annexé
        a ces dernières.
      </Typography>
      <Typography color="dark" component="h3" fontWeight="bold" mt={3} mb={2}>
        21. Divisibilité
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Si une ou plusieurs des dispositions des présentes Conditions sont ou deviennent invalides,
        illégales ou inapplicables à quelque égard que ce soit en vertu d&apos;une loi applicable,
        la validité, la légalité et l&apos;applicabilité des autres dispositions contenues dans les
        présentes
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Conditions ne seront en aucune façon affectées et toute disposition invalide sera considérée
        comme exclue des présentes Conditions. Les Parties conviennent dans ce cas de faire leurs
        meilleurs efforts pour négocier de bonne foi une disposition de remplacement juridiquement
        valide et économiquement équivalente.
      </Typography>
      <Typography color="dark" component="h3" fontWeight="bold" mt={3} mb={2}>
        22. Frais et dépens
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Chaque Partie supporte ses propres coûts, honoraires et dépenses liés à la préparation, la
        négociation, l&apos;exécution et la réalisation des présentes Conditions.
      </Typography>
      <Typography color="dark" component="h3" fontWeight="bold" mt={3} mb={2}>
        23. Droit applicable et juridiction compétente
      </Typography>
      <Typography fontSize="16px" fontWeight="light" color="dark" component="p" mt={2} mb={2}>
        Les présentes Conditions sont régies par et interprétées conformément aux lois du
        Grand-Duché de Luxembourg. Les Parties conviennent irrévocablement que tout litige découlant
        des présentes Conditions ou en rapport avec celles-ci sera soumis exclusivement à la
        compétence des Cours et des Tribunaux du Grand-Duché de Luxembourg, arrondissement
        judiciaire de Luxembourg.
      </Typography>
    </Container>
  );
}

export default TermsConditions;
